// currencyConverter.js
export const customExchangeRates = {
    GBP: 1,
    USD: 1.22,
    EUR: 1.16,
    CAD: 1.66,
    BDT: 134.5,
    INR: 101.35,
    PKR: 337.14,
};

export const convertCurrency = (amount, targetCurrency, customRates) => {
    if (customExchangeRates && customExchangeRates[targetCurrency]) {
        const exchangeRate = customExchangeRates[targetCurrency];
        const convertedAmount = amount * exchangeRate;
        return convertedAmount.toFixed(2);
    } else {
        return "Invalid currency code or custom rate not available";
    }
};

// In this function, amount is the amount in GBP, targetCurrency is the currency code you want to convert to, and customRates is an object containing custom exchange rates for different currencies.