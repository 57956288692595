/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from '../assets/css/BannerSection.module.css'
import { IMAGE_URL } from '../Config/Config';
import { Link, useLocation } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useCart } from 'react-use-cart';
import { searchItems } from '../Utils/method';
import loader from '../assets/loader.svg';
import CurrencyFormat from 'react-currency-format';
import { getCurrencySign } from '../Utils/common';
import AddButton from './AddButton';
// import SubCategory from "../Components/layouts/SidebarItems/SubCategory";
import main_logo from "../assets/main-logo.svg";
import { isMobile } from 'react-device-detect';
import { _domain } from '../Config/Domains';

function FullBannerSection() {
    const product_groups = useSelector((state) => state.rootReducer.product_groups);
    const { inCart } = useCart();
    const [searchValue, setSearchValue] = useState('');
    const [searchLoader, setSearchLoader] = useState(false);
    const [hasNextPage, setHasNextPage] = useState();
    const [paginate_data, setPaginate_data] = useState({ currentPage: 1, totalItems: 0, itemsPerPage: 0 });
    const [nextPage, setNextPage] = useState();
    const [searchedItemList, setSearchedItemList] = useState([]);
    const stores = useSelector((state) => state.rootReducer.stores);

    const fractionCheck = (v) => {
        let data = v.split(' ');
        if (data[1].indexOf('.') === -1) {
            return v;
        } else {
            let frc = data[1].split('.')
            return <><span>{data[0]} {frc[0]}</span>.<span className={styles.fractionValue}>{frc[1]}</span></>
        }
    }

    const fetchMoreData = async () => {
        try {
            if (hasNextPage) {
                let apires = await searchItems({
                    store_id: stores[0]._id,
                    search_text: searchValue,
                    page: nextPage,
                    limit: 20,
                    domain_id: _domain().id
                });
                if (apires.status) {
                    setSearchedItemList([...searchedItemList, ...apires.items]);
                    setPaginate_data(apires.paginate_data)
                    let count = Number(apires.paginate_data.totalItems / apires.paginate_data.itemsPerPage) > apires.paginate_data.currentPage
                    setHasNextPage(count);
                    setNextPage(count ? Number(apires.paginate_data.currentPage + 1) : apires.paginate_data.currentPage);
                }
                console.log('Fetch data: ', apires);
            }
        } catch (error) {
            console.log(error)
        }
    }

    let timer;
    const searchOnKeyup = (e) => {
        clearTimeout(timer);
        timer = setTimeout(async function () {
            setSearchLoader(true);
            var vLength = e.target.value.length
            if (vLength > 0) {
                let apires = await searchItems({
                    store_id: stores[0]._id,
                    search_text: e.target.value,
                    page: 1,
                    limit: 50,
                    domain_id: _domain().id
                });
                if (apires.status) {
                    setSearchedItemList(apires.items);
                    setPaginate_data(apires.paginate_data)
                    let count = Number(apires.paginate_data.totalItems / apires.paginate_data.itemsPerPage) > apires.paginate_data.currentPage
                    setHasNextPage(count);
                    setNextPage(count ? Number(apires.paginate_data.currentPage + 1) : apires.paginate_data.currentPage);
                }
                setSearchLoader(false);
                console.log(apires)
            }
        }, 500);
    }

    const settings = {
        className: "sub_cate_slider",
        centerMode: false,
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 1,
        speed: 500,
        rows: 3,
        slidesPerRow: isMobile ? 3 : 8
    };

    const setScrollPosition = () => {
        const element = document.getElementById('main_content_div')
        element.scrollTo(0, 0);
    }

    return (
        <div className={classNames(styles.full_bannerSection, styles.bannerOnlySearch)}>
            <div className={styles.search_container}>
                <div className={styles.search_container_inner}>
                    {/* Title */}
                    <div className={styles.search_container_title}>
                        Real Brands
                    </div>

                    {/* Search Input */}
                    <div className={styles.search_container_input_section}>
                        <input
                            type="text"
                            className={styles.search_container_input}
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            onKeyUp={searchOnKeyup}
                            placeholder="Search for UK & EU Brands"
                        />
                        {searchValue.length > 0 ? (
                            <span
                                className={styles.search_icon}
                                onClick={() => setSearchValue('')}
                                style={{ fontSize: '22px', cursor: 'pointer' }}
                            >
                                <i className="fa-thin fa-xmark"></i>
                            </span>
                        ) : (
                            <span className={styles.search_icon}>
                                <i className="fa-thin fa-magnifying-glass"></i>
                            </span>
                        )}

                        {/* Search Results */}
                        {searchValue.length > 0 && (
                            <div className={styles.search_result_container}>
                                <div className={styles.searchItemContainer} id="searchItemContainer">
                                    {searchLoader ? (
                                        <div
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <img
                                                style={{ width: '100px' }}
                                                src={loader}
                                                alt="loader"
                                            />
                                        </div>
                                    ) : (
                                        <InfiniteScroll
                                            dataLength={searchedItemList.length}
                                            next={fetchMoreData}
                                            hasMore={hasNextPage}
                                            loader={
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        height: '50px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: '100px' }}
                                                        src={loader}
                                                        alt="loader"
                                                    />
                                                </div>
                                            }
                                            scrollableTarget="searchItemContainer"
                                        >
                                            {searchedItemList.map((v, i) => (
                                                <div
                                                    className={classNames(
                                                        styles.srItemHolder,
                                                        inCart(v._id) ? styles.itemInCart : ''
                                                    )}
                                                    key={'sri_' + i}
                                                >
                                                    <Link
                                                        to={'/single-item/' + v._id}
                                                        className={styles.srItem}
                                                        onClick={() => setSearchValue('')}
                                                    >
                                                        <div className={styles.sri_image}>
                                                            <img
                                                                src={IMAGE_URL + v.image_url[0]}
                                                                alt="sri_img"
                                                            />
                                                        </div>
                                                        <div className={styles.sriDetails}>
                                                            <div className={styles.sri_name}>
                                                                <p>{v.name[0].toLowerCase()}</p>
                                                            </div>
                                                            <div className={styles.sri_price}>
                                                                <CurrencyFormat
                                                                    value={v.price.toFixed(2)}
                                                                    displayType="text"
                                                                    thousandSeparator
                                                                    prefix={getCurrencySign('GBP') + ' '}
                                                                    renderText={(value) => (
                                                                        <p className={styles.uk_price}>
                                                                            {fractionCheck(value)}
                                                                        </p>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Link>
                                                    <div className={styles.srItemAdd}>
                                                        <AddButton data={v} theme="search" />
                                                    </div>
                                                </div>
                                            ))}
                                        </InfiniteScroll>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Bottom Text */}
                    <div className={styles.search_container_bottom_text}>
                        <p
                            className={styles.bottom_text2}
                            style={{ fontSize: '17px' }}
                        >
                            Delivering 100% authentic, high-quality products directly to your doorstep.
                        </p>
                    </div>
                </div>

                {/* Subcategory Slider */}
                <div className={styles.sub_cat_container}>
                    <div className={styles.sub_cat_row}>
                        <Slider {...settings}>
                            {product_groups
                                .filter((t) =>
                                    ['Beauty & Cosmetics', 'Men\'s Fashion', 'Home & Lifestyle', 'Women\'s Fashion'].includes(
                                        t.name[0]
                                    )
                                )
                                .map((v) =>
                                    v?.products.length > 0 &&
                                    v.products.map((vv) => (
                                        <div
                                            className={styles.sub_cats}
                                            onClick={setScrollPosition}
                                            key={vv._id}
                                        >
                                            <Link
                                                to={'/product/' + vv._id}
                                                className={styles.sub_cat_link}
                                            >
                                                <img
                                                    src={IMAGE_URL + vv.image_url}
                                                    alt="cate_img"
                                                    onError={(event) => {
                                                        event.currentTarget.src = main_logo;
                                                    }}
                                                />
                                                <p>{vv.name[0]}</p>
                                            </Link>
                                        </div>
                                    ))
                                )}
                        </Slider>
                    </div>
                </div>
            </div>
        </div>

        // <div className={classNames(styles.full_bannerSection, styles.bannerOnlySearch)}>
        //     <div className={styles.search_container}>
        //         <div className={styles.search_container_inner}>
        //             <div className={styles.search_container_title}>
        //                 {_domain().name === 'ukbd.app' ? 'Real Brands' : _domain().name === 'usbd.app' ? 'US Brands' : 'DESHI PRODUCTS'}
        //             </div>
        //             <div className={styles.search_container_input_section}>
        //                 <input type="text" className={styles.search_container_input} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} onKeyUp={searchOnKeyup}
        //                     placeholder={_domain().name === 'ukbd.app' ? 'Search for UK & EU Brands' : _domain().name === 'usbd.app' ? 'Search for US Brands' : 'Search for deshi products'} />
        //                 {searchValue.length > 0 ?
        //                     <span className={styles.search_icon} onClick={() => setSearchValue('')} style={{ fontSize: '22px', cursor: 'pointer' }}><i className="fa-thin fa-xmark"></i></span>
        //                     :
        //                     <span className={styles.search_icon}><i className="fa-thin fa-magnifying-glass"></i></span>
        //                 }
        //                 {searchValue.length > 0 &&
        //                     <div className={styles.search_result_container}>
        //                         <div className={styles.searchItemContainer} id="searchItemContainer">
        //                             {searchLoader ?
        //                                 <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        //                                     <img style={{ width: '100px', }} src={loader} alt={'loader'} />
        //                                 </div>
        //                                 :
        //                                 <InfiniteScroll
        //                                     dataLength={searchedItemList.length}
        //                                     next={fetchMoreData}
        //                                     hasMore={hasNextPage}
        //                                     // className={styles.searchItemContainer}
        //                                     loader={
        //                                         <div style={{ width: '100%', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        //                                             <img style={{ width: '100px', }} src={loader} alt={'loader'} />
        //                                         </div>
        //                                     }
        //                                     scrollableTarget="searchItemContainer"
        //                                 >
        //                                     {
        //                                         searchedItemList.map((v, i) =>
        //                                             <div className={classNames(styles.srItemHolder, inCart(v._id) ? styles.itemInCart : '')} key={'sri_' + i}>
        //                                                 <Link to={'/single-item/' + v._id} className={styles.srItem} onClick={() => { setSearchValue('') }}>
        //                                                     <div className={styles.sri_image}>
        //                                                         <img src={IMAGE_URL + v.image_url[0]} alt={'sri_img'} />
        //                                                     </div>
        //                                                     <div className={styles.sriDetails}>
        //                                                         <div className={styles.sri_name}><p>{v.name[0].toLowerCase()}</p></div>
        //                                                         <div className={styles.sri_price}>
        //                                                             <CurrencyFormat value={v.price.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={getCurrencySign(_domain().currency) + ' '} renderText={value => <p className={styles.uk_price}>{fractionCheck(value)}</p>} />
        //                                                         </div>
        //                                                     </div>
        //                                                 </Link>
        //                                                 <div className={styles.srItemAdd}>
        //                                                     <AddButton data={v} theme="search" />
        //                                                 </div>
        //                                             </div>
        //                                         )
        //                                     }
        //                                 </InfiniteScroll>
        //                             }
        //                         </div>
        //                     </div>
        //                 }
        //             </div>
        //             {
        //                 _domain().name === 'ukbd.app' ?
        //                     <div className={styles.search_container_bottom_text}>
        //                         <p className={styles.bottom_text2} style={{ fontSize: '17px' }}>Delivering 100% authentic, high-quality products directly to your doorstep.</p>
        //                     </div>
        //                     : _domain().name === 'usbd.app' ?
        //                         <div className={styles.search_container_bottom_text}>
        //                             <p className={styles.bottom_text2} style={{ fontSize: '17px' }}>Delivering 100% authentic American products directly to the doorsteps of Bangladesh.</p>
        //                         </div>
        //                         :
        //                         <div className={styles.search_container_bottom_text}>
        //                             <p className={styles.bottom_text1}>Experience the authentic taste of Bangladeshi food!</p>
        //                             <p className={styles.bottom_text2}>Delivering exclusive Bangladeshi products directly to your doorsteps in the UK.</p>
        //                         </div>
        //             }
        //         </div>
        //         {(_domain().name === 'ukbd.app' || _domain().name === 'usbd.app') ?
        //             <div className={styles.sub_cat_container}>
        //                 <div className={styles.sub_cat_row}>
        //                     <Slider {...settings}>
        //                         {
        //                             product_groups
        //                                 .filter(t => ['Beauty & Cosmetics', 'Men\'s Fashion', 'Home & Lifestyle', 'Women\'s Fashion'].includes(t.name[0]))
        //                                 .map((v, k) => {
        //                                     return (
        //                                         (v?.products).length > 0 && v.products
        //                                             .map(vv =>
        //                                                 <div className={styles.sub_cats} onClick={setScrollPosition} key={vv._id}>
        //                                                     <Link to={"/product/" + vv._id} className={styles.sub_cat_link}>
        //                                                         <img src={IMAGE_URL + vv.image_url} alt="cate_img" onError={(event) => { event.currentTarget.src = main_logo }} />
        //                                                         <p>{vv.name[0]}</p>
        //                                                     </Link>
        //                                                 </div>
        //                                             )
        //                                     )
        //                                 })
        //                         }
        //                     </Slider>
        //                 </div>
        //             </div>
        //             : ''}
        //     </div>
        // </div>
    )
}

export default FullBannerSection;