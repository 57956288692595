'use client'
import styles from '../custom.global.module.css'
import { Link } from 'react-router-dom';


export default function About() {

    let currentIndex = 0;

    const slide_down_1 = () => {
        const allMainContainers = document.querySelectorAll('.main');

        if (allMainContainers.length > 0) {
            currentIndex = (currentIndex + 1) % allMainContainers.length;
            const nextContainer = allMainContainers[currentIndex];
            nextContainer.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <>
            <div className={styles.landing} id="main_content_div">
                <div className={`${styles.product_container}`}>
                    <div className={styles.why_choose_us_container}>
                        <div className={styles.inner_technology_stack_container}>
                            <div className={`${styles.why_choose_us_content_section} ${styles.about_home}`}>
                                <div className={styles.technology_stack_title_section}>
                                    <div className={styles.technology_stack_title}>About Us</div>
                                </div>

                                <div className={styles.technology_stack_item_section}>
                                    <p>Welcome to <Link href={'/'}><span className={styles.ae_custom_logo}>HN Brothers Ltd</span></Link>, where quality meets trust in the realm of global trade. We specialize in providing high-quality used car parts, Perishable Produce, and luxury goods through our well-established supply chains.</p>

                                    <p>At <Link href={'/'}><span className={styles.ae_custom_logo}>HN Brothers Ltd</span></Link>, our passion is delivering products that meet the highest standards of quality. Our team, with extensive experience in sourcing and exporting, ensures that each item we offer—from automotive parts to fresh fruits and vegetables—meets stringent quality checks. Our online platform, <Link href={'#'}>BuyRealBrands</Link>, also brings a curated selection of luxury goods directly to our customers.</p>

                                    <p>We value long-term partnerships and work closely with our clients to understand their unique needs. This commitment to excellence and customer satisfaction drives us to provide tailored solutions that guarantee efficiency and reliability in every transaction.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className={`${styles.product_container} main`}>
                    <div className={styles.product_item}>
                        <div className={styles.left_side}>
                            <div className={styles.inner_section}>
                                <div className={styles.inner_section_title}>About Us</div>
                                <div className={styles.inner_section_sub_title}> Drawing from our extensive experience across diverse industries, including healthcare, finance, e-commerce, and more, we possess the expertise to tackle projects of any magnitude and complexity. Whether {`it's`} streamlining internal processes, enhancing customer engagement, or revolutionizing business models, <Link href={'/'}><span className={styles.ae_custom_logo}>AE</span>  </Link> is dedicated to helping you achieve your goals.</div>
                                <div className={styles.inner_section_sub_title}>What sets us apart is our passion for innovation. We {`don't`} just build software; we create solutions that drive your success. Whether {`you're`} a startup navigating uncharted territories or an established enterprise seeking digital transformation, our customized approach ensures that your software aligns perfectly with your goals.</div>
                                <div className={styles.developButtonContainer} style={{ marginTop: '20px' }}>
                                    <div className={styles.developButtonBottom} onClick={slide_down_1}>Learn More</div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.right_side}>
                            <img className={styles.image_item} src={'#'} unoptimized priority alt=''></img>
                        </div>
                    </div>
                </div>

                <div className={`${styles.product_container} main`}>
                    <div className={styles.product_item}>
                        <div className={styles.right_side}>
                            <img className={styles.image_item} src={'#'} unoptimized priority alt=''></img>
                        </div>

                        <div className={styles.left_side}>
                            <div className={styles.inner_section}>
                                <div className={styles.inner_section_title}>About Us</div>
                                <div className={styles.inner_section_sub_title}> Transparency, integrity, and client satisfaction are the cornerstones of our philosophy. We believe in fostering long-term relationships by not just meeting expectations but surpassing them. Our journey is marked by successful collaborations, and we look forward to embarking on the next chapter with you.</div>
                                <div className={styles.inner_section_sub_title}> Furthermore, our commitment to staying at the forefront of innovation drives us to continuously explore emerging technologies and trends. </div>
                                <div className={styles.inner_section_sub_title}> In essence, at <Link href={'/'}><span className={styles.ae_custom_logo}>AE</span></Link>, we {`don't`} just provide software; we deliver transformative solutions that propel your business into the digital age. Partner with us and experience the seamless convergence of innovation and excellence every step of the way.</div>
                                <div className={styles.developButtonContainer} style={{ marginTop: '20px' }}>
                                    <div className={styles.developButtonBottom} onClick={slide_down_1}>Learn More</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>

        </>
    )
}
