'use client'
import styles from '../process.module.css';
import side_image1 from '../../../assets/fs4.webp';
import side_image2 from '../../../assets/hnbrothers/ex2.jpg';
import side_image3 from '../../../assets/hnbrothers/ex.webp';
import side_image4 from '../../../assets/tech4.webp';

export default function OurProcess() {


    let currentIndex = 0;

    const slide_down_1 = () => {
        const allMainContainers = document.querySelectorAll('.main');

        if (allMainContainers.length > 0) {
            currentIndex = (currentIndex + 1) % allMainContainers.length;
            const nextContainer = allMainContainers[currentIndex];
            nextContainer.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <div className={styles.landing} id="main_content_div">
            <div className={`${styles.product_container} main`}>
                <div className={styles.product_item}>
                    <div className={styles.right_side} style={{ backgroundColor: '#f5f9fc' }}>
                        <img className={styles.image_full} src={side_image4} alt='' />
                    </div>
                    <div className={styles.left_side}>
                        <div className={styles.product_description}>
                            <div className={styles.contentContainer}>
                                <p className={styles.section_title}>Online Retail Process</p>
                                <p className={styles.section_paragraph}>Our online retail process is designed to provide a seamless shopping experience. We curate a selection of high-quality products, including luxury accessories and cosmetics. Each item is carefully vetted to ensure it meets our high standards. From the moment you place an order to delivery, our team is committed to ensuring a smooth and satisfying experience.</p>
                                <div className={styles.developButtonContainer} style={{ marginTop: '20px' }}>
                                    <div className={styles.developButtonBottom} onClick={slide_down_1}>Learn More</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${styles.product_container} main`}>
                <div className={styles.product_item}>
                    <div className={styles.left_side}>
                        <div className={styles.product_description}>
                            <div className={styles.contentContainer}>
                                <p className={styles.section_title}>Import Process</p>
                                <p className={styles.section_paragraph}>Our import process is centered around sourcing fresh and high-quality produce, such as fruits, vegetables, cooking oils, and dry fruits from regions renowned for their natural beauty and agricultural excellence. We collaborate closely with suppliers in Brazil, Portugal, Morocco, and Uzbekistan, ensuring that each product meets our rigorous standards before it is delivered to our clients.</p>
                                <div className={styles.developButtonContainer} style={{ marginTop: '20px' }}>
                                    <div className={styles.developButtonBottom} onClick={slide_down_1}>Learn More</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.right_side}>
                        <img className={styles.image_full} src={side_image2} alt='' />
                    </div>
                </div>
            </div>

            <div className={`${styles.product_container} main`}>
                <div className={styles.product_item}>
                    <div className={styles.right_side}>
                        <img className={styles.image_full} src={side_image3} alt='' />
                    </div>
                    <div className={styles.left_side}>
                        <div className={styles.product_description}>
                            <div className={styles.contentContainer}>
                                <p className={styles.section_title}>Export Process</p>
                                <p className={styles.section_paragraph}>Our export process begins with a thorough assessment of our client{`'`}s needs, focusing on quality and reliability. We meticulously source used car parts and motorcycles from the UK, ensuring that all parts are in running condition. From sourcing to shipment, we adhere to stringent quality checks and regulatory compliance to guarantee that only the best products reach our international customers.</p>
                                <div className={styles.developButtonContainer} style={{ marginTop: '20px' }}>
                                    <div className={styles.developButtonBottom} onClick={slide_down_1}>Learn More</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className={`${styles.product_container} main`}>
                <div className={styles.product_item}>
                    <div className={styles.left_side}>
                        <div className={styles.product_description}>
                            <div className={styles.contentContainer}>
                                <p className={styles.section_title}>Custom Solutions Process</p>
                                <p className={styles.section_paragraph}>Our custom solutions process is tailored to meet the unique needs of our clients. Whether you require specialized automotive parts or a particular type of produce, we work closely with you to define your requirements and deliver a solution that exceeds expectations. Our process is flexible and adaptable, ensuring that we can meet your needs, no matter how specific.</p>
                                <div className={styles.developButtonContainer} style={{ marginTop: '20px' }}>
                                    <div className={styles.developButtonBottom} onClick={slide_down_1}>Learn More</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.right_side} style={{ backgroundColor: '#f5f9fc' }}>
                        <img className={styles.image_full} src={side_image1} alt='' />
                    </div>
                </div>
            </div>

        </div>
    );
}