import React from 'react';
import { Provider } from "react-redux"
import Store from './store'
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import getBrowserFingerprint from 'get-browser-fingerprint';
import Home from './Components/Home';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import "./index.css";
import { CartProvider } from "react-use-cart";
import RouteList from '../src/Config/RouteList'
const fingerprint = getBrowserFingerprint();
console.log('fingerprint:', fingerprint);

const router = createBrowserRouter(
  [
    {
      path: RouteList.home,
      element: <Home />,
    },
    {
      path: RouteList.shop,
      element: <Home />,
    },
    {
      path: RouteList.store_sign_in,
      element: <Home />,
    },
    {
      path: RouteList.store_sign_up,
      element: <Home />,
    },
    {
      path: RouteList.driver_sign_in,
      element: <Home />,
    },
    {
      path: RouteList.driver_sign_up,
      element: <Home />,
    },
    {
      path: RouteList.order_track,
      element: <Home />,
    },
    {
      path: RouteList.sign_in,
      element: <Home />,
    },
    {
      path: RouteList.sign_in_otp,
      element: <Home />,
    },
    {
      path: RouteList.logout,
      element: <Home />,
    },
    {
      path: RouteList.sign_up,
      element: <Home />,
    },
    {
      path: RouteList.forgot_password,
      element: <Home />,
    },
    {
      path: RouteList.popular_products,
      element: <Home />,
    },
    {
      path: RouteList.cart,
      element: <Home />,
    },
    {
      path: RouteList.checkout,
      element: <Home />,
    },
    {
      path: RouteList.payment,
      element: <Home />,
    },
    {
      path: RouteList.payment_success,
      element: <Home />,
    },
    {
      path: RouteList.profile,
      element: <Home />,
    },
    {
      path: RouteList.FAQs,
      element: <Home />,
    },
    {
      path: RouteList.contact_us,
      element: <Home />,
    },
    {
      path: RouteList.terms_conditions,
      element: <Home />,
    },
    {
      path: RouteList.privacy_policy,
      element: <Home />,
    },
    {
      path: RouteList.product_single,
      element: <Home />,
    },
    {
      path: RouteList.single_item,
      element: <Home />,
    },
    {
      path: RouteList.deshi_single_item,
      element: <Home />,
    },
    {
      path: RouteList.category,
      element: <Home />,
    },
    {
      path: RouteList.about,
      element: <Home />,
    },
    {
      path: RouteList.travel,
      element: <Home />,
    },
    // {
    //   path: RouteList.ukbd,
    //   element: <Home />,
    // },
    {
      path: RouteList.deshi,
      element: <Home />,
    },
    {
      path: RouteList.study,
      element: <Home />,
    },
    {
      path: RouteList.migration,
      element: <Home />,
    },
    {
      path: RouteList.sponsorship_application,
      element: <Home />,
    },
    {
      path: RouteList.jobs,
      element: <Home />,
    },
    {
      path: RouteList.property,
      element: <Home />,
    },
    {
      path: RouteList.application,
      element: <Home />,
    },
    {
      path: RouteList.our_services,
      element: <Home />,
    },
    {
      path: RouteList.export_parts,
      element: <Home />,
    },
    {
      path: RouteList.online_shopping,
      element: <Home />,
    },
    {
      path: RouteList.perishable_produce,
      element: <Home />,
    },
    {
      path: RouteList.industries,
      element: <Home />,
    },
    {
      path: RouteList.ourProcces,
      element: <Home />,
    },
    {
      path: RouteList.how_we_work,
      element: <Home />,
    },
    {
      path: RouteList.terms,
      element: <Home />,
    }, {
      path: RouteList.privacy,
      element: <Home />,
    },
    {
      path: RouteList.about,
      element: <Home />,
    },
  ]
);

ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={Store}>
    <CartProvider>
      <RouterProvider router={router} />
    </CartProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
