import { useState } from 'react'
import styles from '../../assets/css/Migration.module.css'
import { useEffect } from 'react'
import { get_item_from_product } from '../../Utils/method'
import Loader from '../Loader'
import { useNavigate } from 'react-router-dom'
import JobApplication from './JobApplication'

const Jobs = () => {
    const navigate = useNavigate()
    const [expand, setExpand] = useState([])
    const [loader, setLoader] = useState(true)
    const [all_jobs, setAll_jobs] = useState([]);
    const [current_job, setCurrent_job] = useState(null);
    const [job_application, setJob_application] = useState(false);

    const manageExpand = (id) => {
        if (expand.indexOf(id) > -1) {
            setExpand(prev => prev.filter(f => f !== id))
        } else {
            setExpand(prev => [...prev, id])
        }
    }

    const format_date = (d) => {
        // const dateString = "2023-09-25T00:00:00.000Z";
        const date = new Date(d);
        // console.log(1, date);
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        // console.log(2, options);
        const formattedDate = date.toLocaleDateString('en-US', options);
        // console.log(3, formattedDate);

        return formattedDate;
    }

    const compare_date = (d) => {
        const targetDate = new Date(d);
        const currentDate = new Date();

        if (currentDate < targetDate) {
            console.log(11);
            return true
        } else if (currentDate > targetDate) {
            console.log(22);
            return false
        } else {
            console.log(33);
            return true
        }
    }

    useEffect(() => {
        let _isMounted = true;

        async function init() {
            setLoader(true)
            try {
                let apires = await get_item_from_product({ product_id: '64e5c601dbc86d3553601259' })
                if (_isMounted) {
                    console.log('get_item_from_product:', apires.items);
                    const reversedArray = apires.items.slice().reverse();
                    setAll_jobs(reversedArray)
                }
                setLoader(false)
            } catch (error) {
                setLoader(false)
                console.log(error);
            }
        }
        init()

        return () => {
            _isMounted = false
        }
    }, [])

    console.log(14, all_jobs);

    return (
        <>
            <div className={styles.jobs_popup}>
                <span className={styles.closeCart} onClick={() => navigate(-1)}><i className="fa-thin fa-xmark"></i></span>
                <div className={styles.jobs_header}>
                    <p className={styles.jobs_header_title}>FIND YOUR DREAM JOB IN THE UK</p>
                    {/* <p className={styles.jobs_header_sub_title}>Become a British citizen through a seamless 5-year Worker Visa Sponsorship facilitated by the HN Brothers marketplace.</p> */}
                </div>
                <div className={styles.jobs_body}>
                    {loader ?
                        <Loader />
                        :
                        all_jobs.map(v =>
                            <div className={styles.expandArea} key={v._id}>
                                <div className={styles.expandAreaHead} onClick={() => manageExpand(v._id)} style={expand.indexOf(v._id) > -1 ? { backgroundColor: '#e5eae8' } : {}}>
                                    <div className={styles.exp_title}>
                                        <p className={styles.mainTitle}>{v.name.toString()} </p>
                                        <div className={styles.exp_title_right}>
                                            <p className={styles.price_range} style={{ marginBottom: '5px' }}>{'£ ' + Number(v.salary_range_start).toLocaleString()} {v.salary_time === 'Per Annum' ? 'pa' : v.salary_time} ({v.location})</p>
                                            {/* {v.salary_range_end === '' || v.salary_range_end === 0 || v.salary_range_end === '0' || v.salary_range_end === '0.00' ?
                                        <p className={styles.price_range} style={{ marginBottom: '5px' }}>{'£ ' + Number(v.salary_range_start).toLocaleString()} {v.salary_time} ({v.location})</p>
                                        :
                                        <p className={styles.price_range} style={{ marginBottom: '5px' }}>{'£ ' + Number(v.salary_range_start).toLocaleString()} to {'£ ' + Number(v.salary_range_end).toLocaleString()} {v.salary_time} ({v.location})</p>
                                    } */}
                                            {/* <p className={styles.price_range} style={{ marginBottom: '5px' }}>{'£ ' + Number(v.salary_range_start).toLocaleString()} to {'£ ' + Number(v.salary_range_end).toLocaleString()} ({v.location})</p> */}
                                            <p className={styles.price_range}>Deadline: {format_date(v.deadline)}</p>
                                        </div>
                                    </div>
                                    <div className={styles.exp_icon}>
                                        {expand.indexOf(v._id) > -1 ? <i className="fa-light fa-minus"></i> : <i className="fa-light fa-plus"></i>}
                                    </div>
                                </div>
                                {expand.indexOf(v._id) > -1 &&
                                    <div className={styles.expandAreaBody}>
                                        <div className={styles.apply_section}>
                                            <button className={styles.apply_form_button} onClick={() => { setCurrent_job(v); setJob_application(true); }} style={compare_date(v.deadline) ? {} : { pointerEvents: 'none', opacity: '0.5' }}>APPLY NOW</button>
                                        </div>
                                        {/* <div className={styles.intro_section}>
                                                <p className={styles.intro_item}> Published:  August 2023 </p>
                                                <p className={styles.intro_item}> Location: Bedfordshire, United Kingdom </p>
                                                <p className={styles.intro_item}> Category: IT Services </p>
                                                <p className={styles.intro_item}> Job Type:  Full-time, Permanent </p>
                                                <p className={styles.intro_item}> Salary:  From £ 45,000 To £ 60,000 Per Annum </p>
                                            </div>
                                            <p className={styles.para_title}>DESCRIPTION</p> */}
                                        <div className={styles.exp_para} dangerouslySetInnerHTML={{ __html: v.details }} />
                                        <div className={styles.apply_section}>
                                            <button className={styles.apply_form_button} onClick={() => { setCurrent_job(v); setJob_application(true); }} style={compare_date(v.deadline) ? {} : { pointerEvents: 'none', opacity: '0.5' }}>APPLY NOW</button>
                                        </div>
                                    </div>
                                }
                            </div>
                        )}
                </div>

            </div>
            {job_application && <JobApplication current_job={current_job} setJob_application={setJob_application} />}
        </>
    )
}

export default Jobs;