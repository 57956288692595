'use client'
import React from 'react'
import Condition from './terms';

export default function Terms() {

    return (
        <Condition />
    )
}
