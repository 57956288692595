import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
// import FacebookLogin from 'react-facebook-login';
import { UserLogin, getUserData, removeUserData, user_send_otp } from "../../../Utils/method"
import isEmail from 'validator/lib/isEmail';
import styles from '../../../assets/css/SignIn.module.css';
import classNames from "classnames";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import getBrowserFingerprint from 'get-browser-fingerprint';
import Countdown from "react-countdown";

function SignInOtp() {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const fingerprint = getBrowserFingerprint();
    console.log(fingerprint);
    const login_modal = useSelector((state) => state.rootReducer.login_modal)
    const [spining, setSpining] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [buttonState, setButtonState] = useState(false);
    const [stage, setStage] = useState(1);
    const [otpValues, setOtpValues] = useState(['', '', '', '', '', '']);
    const inputRefs = useRef([]);
    const [sendtime, setSendTime] = useState(Date.now() + 120000);

    useEffect(() => {
        let state = false;
        if (email === '' || !isEmail(email)) {
            state = false;
        } else {
            state = true
        }

        setButtonState(state);
    }, [email]);

    const LoginUser = async () => {
        if (buttonState) {
            const otpString = otpValues.join('');
            try {
                setSpining(prev => !prev);
                let data = await UserLogin({ email: email, otp: otpString, is_iosapp: false, fingerprint: fingerprint.toString() });
                console.log(40, data.user)
                getUserData({ data: data.user, type: 'set' });
                dispatch({ type: 'set_user_data', payload: data.user });
                toast.success(data.status_phrase, { autoClose: 1000 });
                if (login_modal.from === 'checkout') {
                    dispatch({ type: 'set_login_modal', payload: { from: '', signin: false, signup: false } });
                    navigate("/" + login_modal.from);
                } else {
                    navigate("/");
                }
                setSpining(prev => !prev);
            } catch (error) {
                setSpining(prev => !prev);
                removeUserData();
                toast.error(error.status_phrase, { autoClose: 1000 });
            }
        }
    }

    const sendVerificationOtp = async () => {
        try {
            setSendTime(Date.now() + 120000);
            let apires = await user_send_otp({ email: email });
            console.log(71, apires);
            if (apires.success) {
                setStage(2)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleOtpChange = (index, value) => {
        const newOtpValues = [...otpValues];
        newOtpValues[index] = value;
        setOtpValues(newOtpValues);

        // Automatically focus on the next input field if not the last one
        if (index < inputRefs.current.length - 1 && value !== '') {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleBackspace = (index, event) => {
        if (event.key === 'Backspace' && index > 0 && otpValues[index] === '') {
            const newOtpValues = [...otpValues];
            newOtpValues[index - 1] = '';
            setOtpValues(newOtpValues);

            // Delay focusing to ensure the updated state is reflected
            setTimeout(() => {
                inputRefs.current[index - 1].focus();
            }, 0);
        }
    };

    return (
        <div className={styles.sign_in_page_container}>
            <ToastContainer newestOnTop />
            <div className={styles.carting_head}>
                <span className={styles.closeCart} onClick={() => navigate(-1)}>
                    <i className="fa-thin fa-xmark"></i>
                </span>
            </div>
            <div className={styles.signin_partition}>
                <div className={styles.form_content}>
                    <div className={classNames(styles.signin_form, styles.signin_form_left)}>
                        {stage === 1 ?
                            <div className={styles.container_login}>
                                <h1 className={styles.signin_title}>Sign in with OTP</h1>
                                <div className={styles.inputContSignin}>
                                    <input className={styles.inputs} type="text" placeholder="Email or Phone Number" name="uname" onChange={(event) => setEmail(event.target.value)} required value={email} />
                                </div>
                                <div className={classNames(styles.inputContSignin, spining ? styles.onProcoess : '')}>
                                    <button className={classNames(styles.signin_button, !buttonState ? styles.buttoninactive : '')} type="button" onClick={sendVerificationOtp}>Continue <span className={styles.spiner}> <i className="fa-duotone fa-spinner fa-spin"></i> </span></button>
                                </div>
                            </div>
                            :
                            <div className={classNames(styles.container_login, styles.otp_section)}>
                                <h1 className={styles.signin_title}>Verify Your Email</h1>

                                <p className={styles.sub_title}>Please check your inbox for the verification code send to {email}</p>
                                <p className={styles.opt_label}>Enter Code</p>
                                <div className={styles.opt_input_container}>
                                    {otpValues.map((v, i) => (
                                        <input
                                            key={i}
                                            type="number"
                                            maxLength="1"
                                            value={v}
                                            onChange={(e) => handleOtpChange(i, e.target.value)}
                                            onKeyDown={(e) => handleBackspace(i, e)}
                                            className={styles.opt_input}
                                            ref={(input) => (inputRefs.current[i] = input)}
                                        />
                                    ))}

                                    <p className={styles.opt_resend}>
                                        {/* Resend code in: 60 sec */}
                                        <Countdown date={sendtime} key={sendtime} renderer={props => props.completed ?
                                            <span className={styles.resendBTN} onClick={sendVerificationOtp}><i className="fa-thin fa-arrow-rotate-right"></i> Resend</span>
                                            :
                                            <span className={styles.resendText} style={{ textDecoration: 'none' }} >Resend code in {props.minutes}:{props.seconds} sec</span>} />

                                    </p>
                                </div>
                                <div className={classNames(styles.inputContSignin, spining ? styles.onProcoess : '')}>
                                    <button className={classNames(styles.signin_button, !buttonState ? styles.buttoninactive : '')} type="button" onClick={LoginUser}>Login <span className={styles.spiner}> <i className="fa-duotone fa-spinner fa-spin"></i> </span></button>
                                </div>
                            </div>
                        }
                    </div>
                    <div className={classNames(styles.signin_form, styles.signin_form_right)}>
                        <h1 className={styles.signin_title}>New to HN Brothers . . .</h1>
                        <p className={styles.signintextMsg}>Creating an account is quick and simple and allows  you to track, change or return your order.</p>
                        <Link to={"/sign-up"}> <button className={styles.signin_button} type="button" >CREATE AN ACCOUNT</button></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignInOtp;