'use client'
import React from 'react'
import styles from './mobileNav.module.css'
import { Link, useLocation } from 'react-router-dom';
import { AiOutlineHome } from "react-icons/ai";
import { AiOutlineShop } from "react-icons/ai";
import { AiFillHome } from "react-icons/ai";
import { AiFillShop } from "react-icons/ai";
import { useState, useEffect } from 'react';
import { IoBriefcaseOutline } from "react-icons/io5";
import { IoBriefcase } from "react-icons/io5";
import { SiReact } from "react-icons/si";
import { SiSemanticuireact } from "react-icons/si";
import { AiOutlineUser } from "react-icons/ai";
import { FaUser } from "react-icons/fa"

export default function MobileNav() {

    const location = useLocation();
    const currentPath = location.pathname;

    return (
        <div className={styles.mainContainer}>
            <div className={styles.navButtons}>
                {currentPath === '/' ?
                    <Link to={'/'} className={styles.buttonActive}>
                        <AiFillHome />
                        <p className={styles.buttonText}>Home</p>
                    </Link>
                    :
                    <Link to={'/'} className={styles.button}>
                        <AiOutlineHome />
                        <p className={styles.buttonText}>Home</p>
                    </Link>}
                {currentPath === '/shop' ?
                    <Link to={'/shop'} className={styles.buttonActive}>
                        <AiFillShop />
                        <p className={styles.buttonText}>Shop</p>
                    </Link>
                    :
                    <Link to={'/shop'} className={styles.button}>
                        <AiOutlineShop />
                        <p className={styles.buttonText}>Shop</p>
                    </Link>
                }
                {currentPath === '/our-services' ?
                    <Link to={'/develop'} className={styles.buttonActive}>
                        <SiSemanticuireact />
                        <p className={styles.buttonText}>Services</p>
                    </Link>
                    :
                    <Link to={'/our-services'} className={styles.button}>
                        <SiReact />
                        <p className={styles.buttonText}>Services</p>
                    </Link>
                }
                {currentPath === ('/sign-in') ?
                    <Link to={'/profile'} className={styles.buttonActive}>
                        <FaUser />
                        <p className={styles.buttonText}>Profile</p>
                    </Link>
                    :
                    <Link to={'/sign-in'} className={styles.button}>
                        <AiOutlineUser />
                        <p className={styles.buttonText}>Profile</p>
                    </Link>
                }
            </div>
        </div>
    )
}
