/* eslint-disable react-hooks/exhaustive-deps */
'use client'
import { useState, useEffect } from 'react'
import styles from './terms.module.css';
import { RxCross1 } from "react-icons/rx";

export default function Condition() {
    const [loader, setLoader] = useState(false)
    const [isClient, setIsClient] = useState(false)
    const [domain_data, set_domain_data] = useState(null)

    useEffect(() => {
        setIsClient(true)
    }, [])

    useEffect(() => {
        if (typeof window !== undefined) {
            let d = localStorage.getItem('_domain') ? JSON.parse(localStorage.getItem('_domain')) : null
            console.log(d)
            if (!domain_data) {
                setLoader(false)
                set_domain_data(localStorage.getItem('_domain') ? JSON.parse(localStorage.getItem('_domain')) : null)
            }
        }
    }, [isClient])

    const goBack = () => {
        window.history.back();
    }

    return (
        <>
            <div className={styles.terms_main_container}>
                <div className={styles.mega_menu_close_icon} onClick={goBack}> <RxCross1 style={{ cursor: 'pointer' }} title='Close' /> </div>
                <div className={styles.terms_section}>
                    <div className={styles.terms_header}>Terms and Conditions of HN Brothers Ltd</div>
                    <div className={styles.term_content}>
                        Welcome to HN Brothers Ltd. These terms and conditions govern your use of the HN Brothers Ltd website and the services we provide, including the sale of used car parts, Perishable Produce, and luxury goods.
                        <div className={styles.term_item}>
                            <div className={styles.term_title}>1. Acceptance of Terms</div>
                            <div className={styles.term_title_content}>
                                By accessing and using our website and services, you agree to be bound by these terms and conditions.
                            </div>
                        </div>

                        <div className={styles.term_item}>
                            <div className={styles.term_title}>2. Services</div>
                            <div className={styles.term_title_content}>
                                HN Brothers Ltd offers a range of services including the sale of used car parts, Perishable Produce, and luxury goods through our online platform, BuyRealBrands. Specific terms and conditions related to these services are available upon request.
                            </div>
                        </div>

                        <div className={styles.term_item}>
                            <div className={styles.term_title}>3. User Obligations</div>
                            <div className={styles.term_title_content}>
                                Users of this website must not use it for any unlawful or prohibited purposes. The content on this website is for your general information and personal use only.
                            </div>
                        </div>

                        <div className={styles.term_item}>
                            <div className={styles.term_title}>4. Intellectual Property</div>
                            <div className={styles.term_title_content}>
                                The trademarks, logos, and service marks displayed on this site are the property of HN Brothers Ltd or their respective owners.
                            </div>
                        </div>

                        <div className={styles.term_item}>
                            <div className={styles.term_title}>5. Payment</div>
                            <div className={styles.term_title_content}>
                                All payments for products and services must be made as per the specified terms. Prices are subject to change without notice.
                            </div>
                        </div>

                        <div className={styles.term_item}>
                            <div className={styles.term_title}>6. Cancellation and Refund</div>
                            <div className={styles.term_title_content}>
                                Our cancellation and refund policies are detailed separately and are available upon request.
                            </div>
                        </div>

                        <div className={styles.term_item}>
                            <div className={styles.term_title}>7. Warranty and Liability</div>
                            <div className={styles.term_title_content}>
                                HN Brothers Ltd offers warranties for certain products, detailed separately. Our liability is limited as outlined in our liability policy.
                            </div>
                        </div>

                        <div className={styles.term_item}>
                            <div className={styles.term_title}>8. Changes to Terms</div>
                            <div className={styles.term_title_content}>
                                We reserve the right to modify these terms and conditions at any time.
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

// , and mobile phone sales.Our business address is 9a 172 - Dunstable Road, Luton, Bedfordshire, United Kingdom, LU4 8FG.