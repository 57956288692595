/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../Loader";
import Item from "./Item";
import { isMobile } from "react-device-detect";
import { get_item_from_product, getPopularItems } from "../../../Utils/method"
// import Breadcrumb from "../../Breadcrumb";
import PopularItem from "../Popular/PopularItem";
import BannerSection from "../../BannerSection";
// import Pagination from "./Pagination";
import SiteMap from "./SiteMap";
import FooterSlider from "./FooterSlider";
import FullBannerSection from "../../FullBannerSection";
// import Breadcrumb from "../../Breadcrumb";
import { _domain } from "../../../Config/Domains";
// import Paginate from "./Paginate";
// import FilterOption from './FilterOption'

function ItemSection() {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false)
  const location = useLocation();
  const stores = useSelector((state) => state.rootReducer.stores);
  const paginate_data = useSelector((state) => state.rootReducer.paginate_data);
  const items = useSelector((state) => state.rootReducer.items);
  console.log(30, items)
  const active_product = useSelector((state) => state.rootReducer.active_product);
  const right_side_loader = useSelector((state) => state.rootReducer.right_side_loader);

  const [totalPage, setTotalPage] = useState(1);

  useEffect(() => {
    let page = Math.ceil(paginate_data.totalItems / paginate_data.itemsPerPage);
    setTotalPage(page)
    // console.log({ paginate_data: paginate_data, totalPage: page });
  }, [paginate_data])


  async function fetchMoreData(page) {
    try {
      setLoader(true);
      if (active_product === 'popular') {
        let data = await getPopularItems({
          store_id: stores[0]._id,
          page: page,
          limit: paginate_data.limit,
          domain_id: _domain().id
        })
        console.log('getPopularItems:', data);
        dispatch({ type: 'add_items', payload: data });
      } else {
        let data = await get_item_from_product({
          product_id: active_product,
          page: page,
          limit: paginate_data.limit,
          domain_id: _domain().id
        });
        console.log('Item Section get_item_from_product:', data);
        dispatch({ type: 'add_items', payload: data });
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error)
    }
  }

  const containerRef = useRef(null);

  return (


    <>
      {location.pathname === '/' || location.pathname === '/deshi' ? (
        <>
          <ToastContainer newestOnTop />
          <BannerSection />
          {!right_side_loader && <FullBannerSection />}
        </>
      ) : null}
      {location.pathname === '/shop' ?
        <>
          <FullBannerSection />
        </> : null
      }

      {/* <FilterOption /> */}
      <div
        className="product_section"
        style={!isMobile ? { padding: '12px 80px 40px' } : {}}
        ref={containerRef}
      >
        <div className="parent_for_product">
          {right_side_loader ? (
            <Loader />
          ) : isMobile ? (
            items.map((v) => <PopularItem data={v} key={v._id} />)
          ) :
            (
              items.map((v) => <Item data={v} key={v._id} />)
            )}
        </div>
        {location.pathname !== '/' && items.length > 0 ? (
          <div className="pagination_section">
            {loader ? (
              <Loader />
            ) : (
              <div>
                <div className="total_items_count">
                  {items.length} / {paginate_data.totalItems}
                </div>
                <div
                  className="loadMore_data"
                  style={
                    totalPage > paginate_data.currentPage ? {} : { pointerEvents: 'none' }
                  }
                  onClick={() => fetchMoreData(Number(paginate_data.currentPage + 1))}
                >
                  {totalPage > paginate_data.currentPage
                    ? 'Load More'
                    : 'You have reached the end'}
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div>
      <div className="bottom_banner_section">
        <div className="bottom_banner_title">Discover more products</div>
        <FooterSlider />
      </div>
      <div className="sitemap_section">
        <SiteMap />
      </div>
    </>



    // <>
    //   {location.pathname === '/' || location.pathname === '/deshi' ?
    //     <>
    //       <ToastContainer newestOnTop />
    //       {(_domain().name === 'ukbd.app' || _domain().name === 'usbd.app') &&
    //         <BannerSection />
    //       }
    //       {!right_side_loader && <FullBannerSection />}
    //     </>
    //     : ''
    //   }
    //   {/* <FilterOption /> */}
    //   <div className="product_section" style={!isMobile && _domain().name === 'ukbd.app' ? { padding: '12px 80px 40px' } : {}} ref={containerRef}>
    //     <div className="parent_for_product">
    //       {right_side_loader ? <Loader />
    //         :
    //         isMobile ?
    //           items.map(v => <PopularItem data={v} key={v._id} />)
    //           :
    //           items.map(v => <Item data={v} key={v._id} />)
    //       }
    //     </div>
    //     {location.pathname !== '/' ?
    //       items.length > 0 &&
    //       <div className="pagination_section">
    //         {loader ?
    //           <Loader />
    //           :
    //           <div>
    //             <div className='total_items_count'>{items.length} / {paginate_data.totalItems}</div>
    //             <div className='loadMore_data' style={totalPage > paginate_data.currentPage ? {} : { pointerEvents: 'none' }} onClick={() => fetchMoreData(Number(paginate_data.currentPage + 1))}>{totalPage > paginate_data.currentPage ? 'Load More' : 'You have reached the end'}</div>
    //           </div>
    //         }
    //       </div>
    //       : null
    //     }
    //   </div>
    //   <div className="bottom_banner_section">
    //     <div className="bottom_banner_title">Discover more products</div>
    //     <FooterSlider />
    //   </div>
    //   <div className="sitemap_section">
    //     <SiteMap />
    //   </div>
    // </>
  )
}

export default ItemSection;