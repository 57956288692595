// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DocumentPreview_preview_container__1PFuu {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9990;
    background-color: rgb(255 255 255);
    display: flow-root;
}

.DocumentPreview_preview_header__G\\+UhX {
    width: 100%;
    position: relative;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 60px;
}

.DocumentPreview_preview_header__G\\+UhX h3 {
    color: #012965;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Poppins';
}

.DocumentPreview_preview_body__ULLmf {
    width: 100%;
    height: calc(100% - 60px);
    display: flow-root;
    padding: 20px 60px;
}

.DocumentPreview_pdf_section__pR\\+Go {
    width: 100%;
    height: 100%;
}

.DocumentPreview_img_section__wqfE7 {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.DocumentPreview_img_section__wqfE7 img {
    max-width: 100%;
    max-height: 100%;
}

.DocumentPreview_closeCart__tC54x {
    font-weight: 300;
    border-radius: 2px;
    font-family: var(--robotoC);
    width: 20px;
    height: 20px;
    display: flex;
    font-size: 40px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 25px;
    right: 25px;
    z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/DocumentPreview.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,QAAQ;IACR,MAAM;IACN,WAAW;IACX,YAAY;IACZ,aAAa;IACb,kCAAkC;IAClC,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,sBAAsB;AAC1B;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,2BAA2B;IAC3B,WAAW;IACX,YAAY;IACZ,aAAa;IACb,eAAe;IACf,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,UAAU;AACd","sourcesContent":[".preview_container {\n    position: fixed;\n    right: 0;\n    top: 0;\n    width: 100%;\n    height: 100%;\n    z-index: 9990;\n    background-color: rgb(255 255 255);\n    display: flow-root;\n}\n\n.preview_header {\n    width: 100%;\n    position: relative;\n    height: 60px;\n    display: flex;\n    align-items: center;\n    padding: 0 60px;\n}\n\n.preview_header h3 {\n    color: #012965;\n    font-size: 18px;\n    font-weight: 500;\n    font-family: 'Poppins';\n}\n\n.preview_body {\n    width: 100%;\n    height: calc(100% - 60px);\n    display: flow-root;\n    padding: 20px 60px;\n}\n\n.pdf_section {\n    width: 100%;\n    height: 100%;\n}\n\n.img_section {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.img_section img {\n    max-width: 100%;\n    max-height: 100%;\n}\n\n.closeCart {\n    font-weight: 300;\n    border-radius: 2px;\n    font-family: var(--robotoC);\n    width: 20px;\n    height: 20px;\n    display: flex;\n    font-size: 40px;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n    position: absolute;\n    top: 25px;\n    right: 25px;\n    z-index: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"preview_container": `DocumentPreview_preview_container__1PFuu`,
	"preview_header": `DocumentPreview_preview_header__G+UhX`,
	"preview_body": `DocumentPreview_preview_body__ULLmf`,
	"pdf_section": `DocumentPreview_pdf_section__pR+Go`,
	"img_section": `DocumentPreview_img_section__wqfE7`,
	"closeCart": `DocumentPreview_closeCart__tC54x`
};
export default ___CSS_LOADER_EXPORT___;
