import React from "react";
import { Link } from "react-router-dom";
import styles from '../../assets/css/Footer.module.css'
import RouteList from "../../Config/RouteList";
import { useSelector, useDispatch } from "react-redux";
import flag_uk from '../../assets/UK.png';
import flag_bd from '../../assets/BD.png';
import classNames from "classnames";
// import flag_bd from '../assets/images/flag-BD.png';
import whatsapp from '../../assets/whatsapp.png';
import mail100 from '../../assets/mail100.png';
import { _domain } from "../../Config/Domains";

function Footer() {
    const floatOptions = useSelector((state) => state.rootReducer.floatOptions);
    const dispatch = useDispatch();
    const floatOptionVisibility = () => {
        console.log(floatOptions)
        dispatch({ type: 'set_floatOptions', payload: !floatOptions });
    }
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${'+44 7424 353899'}&&text=${'Hi'}&&type=${'phone_number'}&&app_absent='${0}'`;
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const handleClick = () => {
        if (isMobile) {
            window.location.href = whatsappUrl;
        } else {
            window.open(whatsappUrl, '_blank');
        }
    };
    return (
        <div className={styles.footerSection}>
            <div className={styles.footerInnerContainer}>
                <ul className={styles.footer_ul}>
                    <li className={styles.footerItem}>
                        <Link to={RouteList.about}>About</Link>
                    </li>
                    {/* <li className={styles.footerItem}>
                        <Link to={RouteList.FAQs}>FAQs</Link>
                    </li> */}
                    <li className={styles.footerItem}>
                        <Link to={"/contact-us"}>Contact Us</Link>
                    </li>
                    {/* <li className={styles.footerItem}>
                        <Link to={RouteList.jobs}>Jobs</Link>
                    </li> */}
                    <li className={styles.footerItem}>
                        <Link to={"/terms-and-conditions"}>Terms & Conditions</Link>
                    </li>
                    <li className={styles.footerItem}>
                        <Link to={"/privacy-policy"}>Privacy Policy</Link>
                    </li>
                </ul>
                <div className={styles.socialIcons} onClick={floatOptionVisibility}>
                    <i className="fa-thin fa-chevrons-up"></i>
                </div>
                <div className={styles.uk_bd_flag_container}>
                    {!isMobile &&
                        <>
                            <div className={classNames(styles.flag_img, styles.contact_section)} onClick={handleClick}> <img className={styles.icons} src={whatsapp} alt="" />  +44 7424 353899 </div>
                            <div className={classNames(styles.flag_img, styles.contact_section)}> <img className={styles.icons} src={mail100} alt="" /> <Link to={'mailto:contact@hnbrothers.uk'}> info@hnbrothers.uk </Link></div>
                        </>}
                    <div className={styles.flag_img}> <img className={styles.flag} src={_domain().flag} alt="" /> </div>
                    <div className={styles.flag_img}> <img className={styles.flag} src={flag_bd} alt="" /> </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;