/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import styles from './develop.module.css'
import { Link } from 'react-router-dom';
import s1 from '../../assets/hnbrothers/SERVICES001.webp'
import s2 from '../../assets/hnbrothers/SERVICES002.webp'
import s3 from '../../assets/hnbrothers/SERVICES003.webp'
import s4 from '../../assets/hnbrothers/SERVICES004.webp'




export default function DevelopLanding() {

    let currentIndex = 0;

    const handleScrollDown = () => {
        const allMainContainers = document.querySelectorAll('.main');

        if (allMainContainers.length > 0) {
            currentIndex = (currentIndex + 1) % allMainContainers.length;
            const nextContainer = allMainContainers[currentIndex];
            nextContainer.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };


    return (
        <div className={styles.landing} id="main_content_div">
            <div className={`${styles.main_container} main`}>
                <div className={styles.main_template}>
                    <div className={styles.main_section}>
                        <div className={styles.mainContentBody}>
                            <div className={styles.mainImages}>
                                <div className={styles.imageSideM}>
                                    <img className={styles.main_img} src={s1} unoptimized priority alt='' />
                                </div>
                            </div>
                            <div className={styles.mainContent}>
                                <div className={styles.mainHead}>
                                    <p className={styles.headingMain}> <span> Comprehensive Export and Import Services </span></p>
                                </div>
                                <p className={styles.headingMain}>
                                    Quality Used Car Parts, <br />
                                    Luxury Goods, <br />
                                    Perishable Produce, <br />
                                    and More.
                                </p>
                                <p className={styles.bodyContentP} style={{ marginBottom: '0px' }}>
                                    HN Brothers Ltd excels in exporting high-quality used car parts to markets in Portugal, Morocco, Kyrgyzstan, and the Caribbean. We offer a wide range of parts, from engines to specialized components for brands like Mercedes, BMW, and Toyota, all thoroughly inspected for quality and reliability.
                                </p>
                                <p className={styles.bodyContentP} style={{ marginBottom: '0px' }}>
                                    Our import services focus on bringing fresh fruits, vegetables, cooking oils, and dry fruits from Brazil, Portugal, Morocco, and Uzbekistan, ensuring top-quality produce from naturally rich regions.
                                </p>
                                <p className={styles.bodyContentP} style={{ marginBottom: '0px' }}>
                                    We also source high-end luxury goods, including watches, cosmetics, and more, maintaining our commitment to quality and authenticity in every product we deliver.
                                </p>
                                <div className={styles.developButtonContainer}>
                                    <div className={styles.developButtonBottom} onClick={handleScrollDown}>Learn More</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${styles.main_container} main`}>
                <div className={styles.main_template}>
                    <div className={styles.main_section}>
                        <div className={styles.mainContentBody}>
                            <div className={styles.mainContent}>
                                <div className={styles.mainHead}>
                                    <p className={styles.headingMain}>Global Export of Used Car Parts</p>
                                </div>
                                <p className={styles.bodyContentP}>
                                    HN Brothers Ltd is your trusted partner for the export of used car parts and motorcycles from the UK. We cater to the automotive needs of customers across Portugal, Morocco, Kyrgyzstan, and Caribbean countries. Our inventory includes engines, transmissions, body parts, and more, all sourced from well-maintained vehicles in the UK. With a focus on quality and reliability, we ensure that our parts help keep your vehicles running smoothly and efficiently.
                                </p>
                                <div className={styles.developButtonContainer}>
                                    <div className={styles.developButtonBottom} onClick={handleScrollDown}>Learn More</div>
                                </div>
                            </div>
                            <div className={styles.mainImages}>
                                <div className={styles.imageSideM}>
                                    <img className={styles.main_img} src={s2} unoptimized priority alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${styles.main_container} main`}>
                <div className={styles.main_template}>
                    <div className={styles.main_section}>
                        <div className={styles.mainContentBody}>
                            <div className={styles.mainImages}>
                                <div className={styles.imageSideM}>
                                    <img className={styles.main_img} src={s3} unoptimized priority alt='' />
                                </div>
                            </div>
                            <div className={styles.mainContent}>
                                <div className={styles.mainHead}>
                                    <p className={styles.headingMain}>Import of Perishable Produce and Luxury Goods</p>
                                </div>
                                <p className={styles.bodyContentP}>
                                    HN Brothers Ltd is also a leader in the importation of fresh fruits, vegetables, cooking oils, and dry fruits from Brazil, Portugal, Morocco, and Uzbekistan. Our focus on sourcing from regions renowned for their natural beauty ensures that our produce is of the highest quality. In addition, through our online platform, BuyRealBrands, we bring luxury goods directly to our customers, including watches, cosmetics, and other high-end products. We are committed to offering only the finest items, ensuring satisfaction and excellence in every purchase.
                                </p>
                                <div className={styles.developButtonContainer}>
                                    <div className={styles.developButtonBottom} onClick={handleScrollDown}>Learn More</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${styles.main_container} main`}>
                <div className={styles.main_template}>
                    <div className={styles.main_section}>
                        <div className={styles.mainContentBody}>
                            <div className={styles.mainContent}>
                                <div className={styles.mainHead}>
                                    <p className={styles.headingMain}>Quality Assurance and Customer Commitment</p>
                                </div>
                                <p className={styles.bodyContentP}>
                                    Our commitment to quality is unwavering. Whether exporting automotive parts or importing Perishable produce, we adhere to stringent quality control processes to ensure our products meet the highest standards. At HN Brothers Ltd, customer satisfaction is our top priority. We strive to build long-term relationships by providing reliable products and exceptional service, ensuring that our clients receive the best in quality and value.
                                </p>
                                <Link to={'/contact-us'} className={styles.developButtonContainer}>
                                    <div className={styles.developButtonBottom}>Get In touch</div>
                                </Link>
                            </div>
                            <div className={styles.mainImages}>
                                <div className={styles.imageSideM}>
                                    <img className={styles.main_img} src={s4} unoptimized priority alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

