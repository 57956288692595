'use client'
import styles from '../company/custom.global.module.css';
import image1 from '../../assets//hnbrothers/ind1.webp';
import image2 from '../../assets/hnbrothers/op1.webp';
import image3 from '../../assets/tech5.webp';



export default function TechnologySoftwareDevelopment() {
    let currentIndex = 0;

    const slide_down_1 = () => {
        const allMainContainers = document.querySelectorAll('.main');

        if (allMainContainers.length > 0) {
            currentIndex = (currentIndex + 1) % allMainContainers.length;
            const nextContainer = allMainContainers[currentIndex];
            nextContainer.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };
    return (
        <div className={styles.landing} id="main_content_div">
            <div className={`${styles.product_container} main`}>
                <div className={styles.why_choose_us_container}>
                    <div className={styles.inner_technology_stack_container} style={{ flexDirection: 'column' }}>
                        <div className={styles.why_choose_us_content_section}>
                            <div className={styles.technology_stack_title_section} style={{ height: 'auto' }}>
                                <div className={styles.technology_stack_title}> Comprehensive Industry Solutions </div>
                            </div>

                            <div className={styles.technology_stack_item_section}>
                                <p>HN Brothers Ltd provides specialized solutions for the Automotive, Food & Hospitality, and Retail & E-Commerce industries. Our expertise spans across borders, ensuring quality and efficiency in every sector we serve.</p>
                                <p>In the Automotive industry, we excel in the export of high-quality used car parts, catering to brands like Mercedes, BMW, and Toyota. We ensure that all components meet rigorous quality standards, offering reliability and longevity to our global clients.</p>
                                <p>Our Food & Hospitality services focus on importing the freshest produce from Brazil, Portugal, Morocco, and Uzbekistan. We bring premium fruits, vegetables, oils, and dry fruits to your table, maintaining the highest standards of freshness and quality.</p>
                                <p>For the Retail & E-Commerce industry, we source high-end luxury goods, including watches, cosmetics, and more. We are committed to authenticity and quality, providing top-tier products that resonate with your customers.</p>
                            </div>
                        </div>
                        <div className={styles.developButtonContainer} style={{ marginTop: '20px' }}>
                            <div className={styles.developButtonBottom} onClick={slide_down_1}>Learn More</div>
                        </div>

                    </div>
                </div>
            </div>
            <div className={`${styles.product_container} main`}>
                <div className={styles.product_item}>
                    <div className={styles.left_side}>
                        <div className={styles.inner_section}>
                            <div className={styles.inner_section_title}>Retail & E-Commerce Expertise</div>
                            <div className={styles.inner_section_sub_title}>
                                We excel in sourcing and supplying high-end luxury goods, cosmetics, and other premium products for the retail and e-commerce sectors. Our curated selection includes globally recognized brands, ensuring authenticity and superior quality in every item we offer. Whether you are looking to enhance your product range or provide your customers with exclusive items, our expertise in logistics and procurement ensures timely delivery and exceptional service, supporting your business in achieving its growth objectives.
                            </div>
                            <div className={styles.developButtonContainer} style={{ marginTop: '20px' }}>
                                <div className={styles.developButtonBottom} onClick={slide_down_1}>Learn More</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.right_side}>
                        <img className={styles.image_item} src={image3} unoptimized priority alt='' />
                    </div>
                </div>
            </div>


            <div className={`${styles.product_container} main`}>
                <div className={styles.product_item}>
                    <div className={styles.right_side}>
                        <img className={styles.image_item} src={image2} unoptimized priority alt='' />
                    </div>
                    <div className={styles.left_side}>
                        <div className={styles.inner_section}>
                            <div className={styles.inner_section_title}>Food & Hospitality Excellence</div>
                            <div className={styles.inner_section_sub_title}>
                                In the food and hospitality industry, we pride ourselves on sourcing and importing the freshest and highest-quality produce from fertile regions worldwide. Our selection includes seasonal fruits, vegetables, and specialty items that enhance culinary offerings in restaurants, hotels, and catering businesses. With a commitment to quality and sustainability, we ensure that every product we deliver is carefully selected to meet the diverse needs of our clients, helping them create exceptional dining experiences.
                            </div>
                            <div className={styles.developButtonContainer} style={{ marginTop: '20px' }}>
                                <div className={styles.developButtonBottom} onClick={slide_down_1}>Learn More</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${styles.product_container} main`}>
                <div className={styles.product_item}>
                    <div className={styles.left_side}>
                        <div className={styles.inner_section}>
                            <div className={styles.inner_section_title}>Automotive Solutions</div>
                            <div className={styles.inner_section_sub_title}>
                                Our expertise in the automotive industry allows us to offer top-quality used car parts that are rigorously tested to ensure they meet the highest standards. We provide a comprehensive range of components, including engines, transmissions, and specialized parts for luxury brands like Mercedes, BMW, and Toyota. Whether you are looking to maintain the performance of your vehicle or seeking specific parts for restoration projects, our reliable offerings ensure durability and value.
                            </div>
                            <div className={styles.developButtonContainer} style={{ marginTop: '20px' }}>
                                <div className={styles.developButtonBottom} onClick={slide_down_1}>Learn More</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.right_side}>
                        <img className={styles.image_item} src={image1} unoptimized priority alt='' />
                    </div>
                </div>
            </div>


        </div>
    )
}