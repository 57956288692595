import React from 'react';
import styles from '../../assets/css/Profile.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function SignupWarning(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const signupWarning = useSelector((state) => state.rootReducer.signupWarning)

    const actionOnClick = () => {
        dispatch({ type: 'set_signupWarning', payload: { warning: false, data: {} } })
        navigate('/');
    }

    return (
        <div className={styles.wrapper} style={{ width: '100%' }}>
            <span className={styles.closeCart} onClick={actionOnClick}>
                <i className="fa-thin fa-xmark"></i>
            </span>
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <h2 className={styles.accountStatusTitle}>Info</h2>
                    <p className={styles.accountStatusMsg}>Hi, <span style={{ fontWeight: '500', whiteSpace: 'nowrap' }}>{signupWarning.data.first_name + ' ' + signupWarning.data.last_name} </span> Thank you for completing your Account Registration with HN Brothers {signupWarning.data.type} App.
                        We have received your profile detail. Our representatives will contact you soon about what to do next.</p>
                    <p className={styles.accountStatusMsg}>Please also check your email inbox <span style={{ fontWeight: '500', whiteSpace: 'nowrap' }}>{signupWarning.data.email}</span> to verify your email address and also phone number if you haven’t already did that.</p>
                </div>

                <div className={styles.timerSection}>
                    <h2 className={styles.accountStatusBtns}>
                        <button className={styles.accountAction} onClick={actionOnClick}>OK</button>
                    </h2>
                </div>
            </div>
        </div>
    )
}
