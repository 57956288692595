import React from 'react'
import ItemSection from './pages/Items/ItemSection'

export default function Shop() {
    return (
        <>
            <ItemSection />
        </>
    )
}
