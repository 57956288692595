/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ItemSection from "../pages/Items/ItemSection"
import { useLocation, useParams } from 'react-router-dom';
import { get_item_from_product, getUserData } from "../../Utils/method";
import SignIn from '../pages/SignIn/SignIn';
import SignUp from '../pages/SignUp/SignUp';
import Logout from '../pages/Logout/Logout';
import RouteList from '../../Config/RouteList';
import HelpFAQs from '../pages/HelpFAQs/HelpFAQs';
import ContactUs from '../pages/ContactUs/ContactUs';
import TermsConditions from '../pages/TermsConditions/TermsConditions';
import PrivacyPolicy from '../pages/PrivacyPolicy/PrivacyPolicy';
import FloatOption from '../pages/Items/FloatOption';
import StoreSignIn from '../pages/Store/StoreSignIn';
import StoreSignUp from '../pages/Store/StoreSignUp';
import DriverSignIn from '../pages/Driver/DriverSignIn';
import DriverSignUp from '../pages/Driver/DriverSignUp';
import Profile from '../pages/Profile/Profile';
import Checkout from '../pages/Checkout/Checkout';
import OrderTracking from '../pages/Map/OrderTracking';
import SingleItem from '../pages/Items/SingleItem';
import ScrollToTop from '../pages/ScrollToTop';
import Cart from '../pages/Cart/Cart';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import Category from '../pages/Category/Category';
import Popular from '../pages/Popular/Popular';
import SubmitForm from '../SubmitForm';
// import ChatBot from '../popups/ChatBot';
import Payment from '../pages/Checkout/Payment';
import PaymentSuccess from '../pages/Checkout/PaymentSuccess';
import classNames from 'classnames';
import FilterOption from '../pages/Items/FilterOption';
import { isMobile } from 'react-device-detect';
import OrderPopup from '../OrderPopup';
import SignInOtp from '../pages/SignIn/SignInOtp';
import Study from '../Study/Study';
import FindYourHost from '../Travel/FindYourHost';
import Property from '../Property/Property';
import Migration from '../Migration/Migration';
import Sponsorship from '../Migration/Sponsorship';
import { _domain } from '../../Config/Domains';
import Jobs from '../Migration/Jobs';
import Products from '../pages/Products/Products';
import OurServices from '../our-services/Our-Services'
import ExportParts from '../our-services/export-parts/Export-Parts'
import OnlineShopping from '../our-services/online-shopping/Online-Shopping'
import PerishableProduce from '../our-services/perishable-produce/Perishable-Produce'
import Industries from '../industries/Industries'
import HowWeWork from '../company/how-we-work/HowWeWork'
import About from '../company/about/About'
import OurProcess from '../company/our-process/OurProcess'
import Terms from '../terms-and-conditions/TermPage'
import Privacy from '../privacy-policy/PrivacyPage'
import Shop from '../Shop';

const GetComponent = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    if (location.pathname === RouteList.home) {
        return (<ItemSection />)
    } else if (location.pathname === RouteList.migration) {
        return <Migration />
    } else if (location.pathname === RouteList.application) {
        return <SubmitForm />
    } else if (location.pathname === RouteList.forgot_password) {
        return <ForgotPassword />
    } else if (location.pathname === RouteList.sign_up) {
        if (getUserData({ type: 'get' })) {
            window.location.href = "/";
        } else {
            return (<SignUp />);
        }
    } else if (location.pathname === RouteList.sign_in) {
        if (getUserData({ type: 'get' })) {
            window.location.href = "/";
        } else {
            return (<SignIn />)
        }
    } else if (location.pathname === RouteList.sign_in_otp) {
        if (getUserData({ type: 'get' })) {
            window.location.href = "/";
        } else {
            return (<SignInOtp />)
        }
    } else if (location.pathname === RouteList.store_sign_in) {
        return (<StoreSignIn />)
    } else if (location.pathname === RouteList.store_sign_up) {
        return (<StoreSignUp />)
    } else if (location.pathname === RouteList.driver_sign_in) {
        return (<DriverSignIn />)
    } else if (location.pathname === RouteList.driver_sign_up) {
        return (<DriverSignUp />)
    } else if (location.pathname === RouteList.logout) {
        return (<Logout />)
    } else if (location.pathname.indexOf('/track-order/') > -1) {
        if (getUserData({ type: 'get' })) {
            return (<OrderTracking />)
        } else {
            window.location.href = "/";
        }
    }
    else if (location.pathname === RouteList.our_services) {
        return (<OurServices />)
    }
    else if (location.pathname === RouteList.export_parts) {
        return (<ExportParts />)
    }
    else if (location.pathname === RouteList.online_shopping) {
        return (<OnlineShopping />)
    }
    else if (location.pathname === RouteList.perishable_produce) {
        return (<PerishableProduce />)
    }

    else if (location.pathname === RouteList.industries) {
        return (<Industries />)
    }
    else if (location.pathname === RouteList.ourProcces) {
        return (<OurProcess />)
    }
    else if (location.pathname === RouteList.how_we_work) {
        return (<HowWeWork />)
    }
    else if (location.pathname === RouteList.about) {
        return (<About />)
    }
    else if (location.pathname === RouteList.terms) {
        return (<Terms />)
    }
    else if (location.pathname === RouteList.privacy) {
        return (<Privacy />)
    }
    else if (location.pathname === RouteList.shop) {
        return <Shop />
    }

    else if (location.pathname === RouteList.FAQs) {
        return (<HelpFAQs />)
    } else if (location.pathname === RouteList.contact_us) {
        return (<ContactUs />)
    } else if (location.pathname === RouteList.study) {
        return (<Study />)
    } else if (location.pathname === RouteList.sponsorship_application) {
        return (<Sponsorship />)
    } else if (location.pathname === RouteList.jobs) {
        return (<Jobs />)
        // } else if (location.pathname === RouteList.job_application) {
        //     return (<JobApplication />)
    } else if (location.pathname === RouteList.travel) {
        return (<FindYourHost />)
    } else if (location.pathname === RouteList.property) {
        return (<Property />)
    } else if (location.pathname === RouteList.terms_conditions) {
        return (<TermsConditions />)
    } else if (location.pathname === RouteList.privacy_policy) {
        return (<PrivacyPolicy />)
    } else if (location.pathname === RouteList.checkout) {
        if (getUserData({ type: 'get' })) {
            return (<Checkout />)
        } else {
            dispatch({ type: 'set_login_modal', payload: { from: 'checkout', signin: false, signup: false } });
            return (<SignIn />)
        }
    } else if (location.pathname === RouteList.payment) {
        if (getUserData({ type: 'get' })) {
            return (<Payment />)
        } else {
            dispatch({ type: 'set_login_modal', payload: { from: 'checkout', signin: false, signup: false } });
            return (<SignIn />)
        }
    } else if (location.pathname === RouteList.payment_success) {
        if (getUserData({ type: 'get' })) {
            return (<PaymentSuccess />)
        } else {
            dispatch({ type: 'set_login_modal', payload: { from: 'checkout', signin: false, signup: false } });
            return (<SignIn />)
        }
    } else if (location.pathname === RouteList.cart) {
        return (<Cart />)
    } else if (location.pathname === RouteList.profile) {
        if (getUserData({ type: 'get' })) {
            return (<Profile />)
        } else {
            window.location.href = "/";
        }
    } else if (location.pathname.indexOf('/category/') > -1) {
        return (<Category />)
    } else if (location.pathname === RouteList.popular_products) {
        return (<Popular />)
    } else if (location.pathname.indexOf('/single-item/') > -1 || location.pathname.indexOf('/deshi/single-item/') > -1) {
        return (<SingleItem />)
    } else if (location.pathname.indexOf('/product/') > -1) {
        console.log(183, 'product Location');
        return (<Products />)
    } else {
        return (<ItemSection />)
    }
}

function MainContent() {
    const location = useLocation();
    const [showTopBtn, setShowTopBtn] = useState(false);
    const mobile_filter = useSelector((state) => state.rootReducer.mobile_filter)
    const mobile_orders = useSelector((state) => state.rootReducer.mobile_orders)
    const containerRef = useRef(null);

    const scrollToTop = () => {
        let elm = document.querySelector('#main_content_div')
        elm.scrollTo({ top: 0, behavior: "smooth" });
    }

    useEffect(() => {
        let elm = document.querySelector('#main_content_div')
        elm.addEventListener("scroll", (e) => {
            if (elm.scrollTop > 400) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);

    return (
        <>
            <ToastContainer newestOnTop />
            <div className={classNames('MainContent')} ref={containerRef} style={location.pathname !== '/profile' && location.pathname.indexOf('/map') === -1 ? {} : { height: '100%', padding: 0 }} id="main_content_div" >
                <GetComponent />
                {(isMobile && mobile_filter) && <FilterOption />}
                {(isMobile && mobile_orders) && <OrderPopup />}
            </div>
            {/* {!isMobile && <FloatOption />} */}
            {showTopBtn && <ScrollToTop goToTop={scrollToTop} />}
            {/* <ChatBot /> */}
        </>
    )
}

export default MainContent;