import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useCart } from "react-use-cart";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IMAGE_URL } from "../../Config/Config";
import UserHeadSection from "./UserHeadSection";
import CurrencyFormat from 'react-currency-format';
import { IoBagSharp } from 'react-icons/io5';
import styles from '../../assets/css/Header.module.css';
import style from './header.module.css'
import { get_orders, get_favourite_item_list } from '../../Utils/method';
import loader from '../../assets/loader.svg';
import { useEffect } from "react";
import Orders from "../Orders";
import ItemPrice from "../pages/Items/ItemPrice";
import classNames from "classnames";
import AddButton from "../AddButton";
import { getCurrencySign } from "../../Utils/common";
import SearchSuggest from "./SearchSuggest";
import UkEuPopup from "../popups/Uk_Eu_Popup";
import DeshiPopup from "../popups/DeshiPopup";
import TravelPopup from "../popups/TravelPopup";
import StudyPopup from "../popups/StudyPopup";
import WorkPopup from "../popups/WorkPopup";
import PropertyPopup from "../popups/PropertyPopup";
import MoneyPopup from "../popups/MoneyPopup";
import { _domain } from "../../Config/Domains";
import Currency from "./Currency";
import { FaGem } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import { FaPhoneAlt } from "react-icons/fa";
import { RxHamburgerMenu } from "react-icons/rx";
import { HiOutlinePlus } from "react-icons/hi2";
import { HiOutlineMinus } from "react-icons/hi2";
import HnLogo from '../../assets/hnBrother.png'

function Header() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const select_item = localStorage.getItem('delivery_id');
    const delivery = useSelector((state) => state.rootReducer.delivery);
    const currency_sign = useSelector((state) => state.rootReducer.currency_sign);
    // const stores = useSelector((state) => state.rootReducer.stores);
    const user_data = useSelector((state) => state.rootReducer.user_data);
    const [activeDelivery] = useState(select_item === '' || select_item === null ? delivery[0] : delivery.filter(v => v._id === select_item)[0])
    const {
        isEmpty,
        totalUniqueItems,
        cartTotal
    } = useCart();
    const [searchState, setSearchState] = useState(false);
    const [searchPopup, setSearchPopup] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [searchedItemList, setSearchedItemList] = useState([]);
    const [cartOption, setCartOption] = useState(false);
    const [showOrderList, setShowOrderList] = useState(false);
    const [searchLoader, setSearchLoader] = useState(false);
    const [orderList, setOrderList] = useState([]);
    const [isClient, setIsClient] = useState(false);
    const [activeMenu, setActiveMenu] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null);

    const toggleMenu = () => {
        setIsMenuOpen(prevState => !prevState);
    };

    const navigation = () => {
        const el = document.getElementById('left_sidebar');
        console.log(el.classList)
        const element = document.getElementById('ukeubrands');
        if (el.classList.contains('hide_nav')) {
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'end' })
            }
            el.classList.remove('hide_nav');
        } else {
            el.classList.add('hide_nav');
        }
    }

    const getAllorders = async () => {
        try {
            let apires = await get_orders();
            if (apires.success) {
                setOrderList([...apires.order_list]);
                dispatch({ type: 'set_ordersList', payload: apires.order_list });
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getFavourite = async () => {
        try {
            let apires = await get_favourite_item_list();
            if (apires.success) {
                let ids = [];
                for (let index = 0; index < apires.favourite_items.length; index++) {
                    const element = apires.favourite_items[index];
                    ids.push(element._id)
                }
                dispatch({ type: "set_allfavourite_items", payload: apires.favourite_items })
                dispatch({ type: "set_favourite_items", payload: ids })
            }
        } catch (error) {
            console.log(error);
        }
    }

    const gotoHome = () => {
        navigate('/')
    }

    useEffect(() => {
        if (user_data) {
            getAllorders();
            getFavourite();
        } else {
            setOrderList([]);
            dispatch({ type: "set_favourite_items", payload: [] })
        }
    }, [user_data])

    const navigateTocart = (type) => {
        console.log('activeDelivery:', activeDelivery)
        localStorage.setItem('currency_data', JSON.stringify({
            payment_currency: type,
            view_price_currency: _domain().currency,
            exchange_rate_website: activeDelivery?.conversion_rate,
        }))
        setCartOption(false);
        setSearchPopup(false)
        if (!isEmpty) navigate('/cart')
    }

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.keyCode === 27) {
                if (searchPopup) {
                    setSearchPopup(false)
                }
            }
        };
        window.addEventListener('keydown', handleEsc);
        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, []);

    const [menu_popup, setMenu_popup] = useState('')
    const showMenuPopup = (type) => {
        setMenu_popup(type)
    }

    const clickToNavigate = (e, type, hash) => {
        if (_domain().name === 'ukbd.app' || _domain().name === 'usbd.app') {
            e.preventDefault();
            e.stopPropagation();
            if (type === 'ukbd') {
                const element = document.getElementById(hash);
                if ((location.pathname.indexOf('/product/') > -1 || location.pathname.indexOf('/single-item/') > -1) || location.pathname === '/deshi' || location.pathname === '/travel' || location.pathname === '/study' || location.pathname === '/property') {
                    navigate('/#ukeubrands')
                    setTimeout(() => {
                        if (element) {
                            element.scrollIntoView({ behavior: 'smooth', block: 'end' })
                        }
                    }, 200);
                }
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'end' })
                }
                // } else if (type === 'deshi') {
                //     navigate('/' + type)
            } else if (type === 'travel') {
                navigate('/' + type)
            } else if (type === 'study') {
                navigate('/' + type)
            } else if (type === 'migration') {
                navigate('/' + type)
            } else if (type === 'property') {
                navigate('/' + type)
            }
        }
    }
    const pathname = location.pathname
    const [currentPath, setCurrentPath] = useState('');
    useEffect(() => {
        setCurrentPath(window.location.pathname);
        const handleRouteChange = () => {
            setCurrentPath(window.location.pathname);
        };
        window.addEventListener('popstate', handleRouteChange);
        return () => {
            window.removeEventListener('popstate', handleRouteChange);
        };
    }, []);


    const [open_menu, set_open_menu] = useState('')
    const [expandedMenus, setExpandedMenus] = useState({});
    const handleExpand = (menuKey) => {
        setExpandedMenus(prevState => ({
            ...prevState,
            [menuKey]: !prevState[menuKey]
        }));
    };
    return (

        <>
            <header className={style.main_header}>
                <div className={style.head_container}>
                    <div className={style.menu_section}>
                        <Link to={'/'} className={style.menu_logo}>
                            {/* <Image src={AE_logo_new} alt="Logo" className={style.Logo2} onClick={() => gotoTop('main_page')} /> */}
                            <img src={HnLogo} className={style.Logo2} alt='hn brother logo' />
                            <span className={style.logoText}>HN Brothers</span>
                        </Link>
                        <div className={style.menu_group}>
                            <div className={style.menu_items}>
                                <div className={style.LinkBox} >
                                    <Link to="/shop"><div className={`${style.menu_item_list} ${pathname.indexOf('/company') > -1 ? style.active : ''}`} onMouseEnter={() => set_open_menu('')} >Shop  </div> </Link>
                                    <Link to={'/our-services'}><div className={`${style.menu_item_list} ${pathname.indexOf('/develop') > -1 ? style.active : ''}`} onMouseEnter={() => set_open_menu('service')} > Services </div></Link>
                                    <Link to="#"><div className={`${style.menu_item_list} ${pathname.indexOf('/company') > -1 ? style.active : ''}`} onMouseEnter={() => set_open_menu('company')} >Company  </div> </Link>
                                    <Link to="#"><div className={`${style.menu_item_list} ${pathname.indexOf('/industries') > -1 ? style.active : ''}`} onMouseEnter={() => set_open_menu('industries')} >Industries </div> </Link>
                                </div>
                            </div>
                        </div>
                        <div className={style.menu_group}>
                            <div className={styles.all_menu_section_right}>
                                <Link className={styles.menu_title} onMouseOver={() => showMenuPopup('work')} onClick={(e) => { clickToNavigate(e, 'migration', ''); showMenuPopup('') }} to={"https://job.ukbd.app"}> <span className={styles.menu_label}> CAREER</span></Link>
                                <div className={classNames(styles.checkout_box, 'cartBoxContainer')} onMouseOver={() => showMenuPopup('')}>
                                    <p onClick={() => navigateTocart('GBP')}>
                                        <IoBagSharp color="#00403e" size={16} style={{ position: 'relative', top: '-1px' }} />
                                        {totalUniqueItems > 0 && <span className={styles.pro_qty}>[{totalUniqueItems}]</span>}
                                        <span className={styles.pro_price}>
                                            <CurrencyFormat value={Number(cartTotal).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={getCurrencySign(_domain().currency) + ' '} renderText={value => value} />
                                        </span>
                                    </p>
                                    <span className={styles.cartDropdown} style={cartOption ? { pointerEvents: 'none' } : {}} onClick={() => setCartOption(!cartOption)}>
                                        <i className="fa-thin fa-chevron-down"></i>
                                    </span>
                                    <ItemPrice type={'cart'} navigateTocart={navigateTocart} data={''} activeDelivery={activeDelivery} currency_sign={currency_sign} offer_text={''} previousPrice={''} />
                                </div>
                                <div className={styles.currency_section} onMouseOver={() => showMenuPopup('')}>
                                    <Currency />
                                </div>

                                <div className={styles.account_section} onMouseOver={() => showMenuPopup('')}>
                                    <UserHeadSection />
                                </div>
                            </div>
                        </div>
                        {open_menu === 'service' && (
                            <div className={`${style.mega_menu_section}`} onMouseLeave={() => set_open_menu('')} >
                                <div className={style.mega_menu_close_icon}> <RxCross1 style={{ cursor: 'pointer' }} title='Close' onClick={() => set_open_menu('')} /> </div>
                                <div className={style.mega_menu_item_container_services}>
                                    <Link onClick={() => set_open_menu('')} to="/our-services/online-shopping" className={style.mega_menu_item_section}>
                                        <div className={style.mega_menu_title} >Online Retail of Quality Goods</div>
                                        <li className={style.mega_menu_content}> Through our platform, we offer a range of high-quality products including watches, cosmetics, and other luxury and makeup items, ensuring a premium shopping experience.</li>
                                    </Link>
                                    <Link onClick={() => set_open_menu('')} to="/our-services/perishable-produce" className={style.mega_menu_item_section}>
                                        <div className={style.mega_menu_title} >Import of Perishable Produce</div>
                                        <li className={style.mega_menu_content}> We import fresh fruits, vegetables, cooking oils, and dry fruits from Brazil, Portugal, Morocco, and Uzbekistan, sourcing from regions known for their premium quality and natural beauty.</li>
                                    </Link>
                                    <Link onClick={() => set_open_menu('')} to="/our-services/export-parts" className={style.mega_menu_item_section}>
                                        <div className={style.mega_menu_title} >Export of Car Parts</div>
                                        <li className={style.mega_menu_content}> We export high-quality used car parts and motorcycles from the UK to Portugal, Morocco, Kyrgyzstan, and Caribbean countries, including engines and parts for brands like Mercedes, BMW, and Toyota.</li>
                                    </Link>
                                </div>
                            </div>
                        )}
                        {open_menu === 'company' && (
                            <div className={style.mega_menu_section} onMouseLeave={() => set_open_menu('')} >
                                <div className={style.mega_menu_close_icon}> <RxCross1 style={{ cursor: 'pointer' }} title='Close' onClick={() => set_open_menu('')} /> </div>
                                <div className={style.mega_menu_item_container_services}>

                                    <Link onClick={() => set_open_menu('')} to='/company/about' className={style.mega_menu_item_section}>
                                        <div className={style.mega_menu_title} >About Us</div>
                                        <li className={style.mega_menu_content}> Hn Brothers, Delivering quality parts, Perishable Produce, and luxury goods with excellence. </li>
                                    </Link>
                                    <Link onClick={() => set_open_menu('')} to={'/company/how-we-work'} className={style.mega_menu_item_section}>
                                        <div className={style.mega_menu_title}>How we work</div>
                                        <li className={style.mega_menu_content}>At HN Brothers Ltd, we focus on delivering quality and reliability across all our services.</li>
                                    </Link>


                                    <Link onClick={() => set_open_menu('')} to={'/company/our-procces'} className={style.mega_menu_item_section}>
                                        <div className={style.mega_menu_title}>Our Process</div>
                                        <li className={style.mega_menu_content}>From sourcing to delivery, we ensure quality and customer satisfaction.</li>
                                    </Link>
                                </div>
                            </div>
                        )}
                        {open_menu === 'industries' && (
                            <div className={style.mega_menu_section} onMouseLeave={() => set_open_menu('')} >
                                <div className={style.mega_menu_close_icon}> <RxCross1 style={{ cursor: 'pointer' }} title='Close' onClick={() => set_open_menu('')} /> </div>
                                <div className={style.mega_menu_item_container_services}>
                                    <div className={style.mega_menu_item_container}>
                                        <Link onClick={() => set_open_menu('')} to={'/industries'} className={style.mega_menu_item_section}>
                                            <div className={style.mega_menu_title}>Retail and E-Commerce</div>
                                            <li className={style.mega_menu_content}>Partner with high-end retailers and online platforms to offer an exclusive selection of premium accessories and beauty products, enhancing their range with our curated, luxury items.</li>
                                        </Link>
                                    </div>
                                    <div className={style.mega_menu_item_container}>
                                        <Link onClick={() => set_open_menu('')} to={'/industries'} className={style.mega_menu_item_section}>
                                            <div className={style.mega_menu_title}>Perishable Products</div>
                                            <li className={style.mega_menu_content}>Work with restaurants, cafes, and catering businesses to provide Perishable Produce and maintain high standards for their ingredients.</li>
                                        </Link>
                                        {/* <Link onClick={() => set_open_menu('')} href={'/industries/healthcare-software'} className={style.mega_menu_item_section}>
                                            <div className={style.mega_menu_title}>Healthcare</div>
                                            <li className={style.mega_menu_content}>Custom-built solutions that redefine healthcare and patient management</li>
                                        </Link> */}
                                    </div>
                                    <div className={style.mega_menu_item_container}>

                                        <Link onClick={() => set_open_menu('')} to={"/industries"} className={style.mega_menu_item_section}>
                                            <div className={style.mega_menu_title} >Automotive Industry</div>
                                            <li className={style.mega_menu_content}> Partner with repair shops, auto dealerships, and automotive parts retailers to supply high-quality used car parts.</li>
                                        </Link>
                                        {/* <Link onClick={() => set_open_menu('')} href={"/industries/sports-software-development"} className={style.mega_menu_item_section}>
                                            <div className={style.mega_menu_title}>Sports</div>
                                            <li className={style.mega_menu_content}>Bespoke sports software solutions including sports apps, websites, and more!</li>
                                        </Link> */}
                                    </div>



                                </div>
                            </div>

                        )}
                        <div className={style.cartMobile}>
                            <div className={styles.all_menu_section_right}>
                                <div className={classNames(styles.checkout_box, 'cartBoxContainer')} onMouseOver={() => showMenuPopup('')}>
                                    <p onClick={() => navigateTocart('GBP')}>
                                        <IoBagSharp color="#00403e" size={16} style={{ position: 'relative', top: '-1px' }} />
                                        {totalUniqueItems > 0 && <span className={styles.pro_qty}>[{totalUniqueItems}]</span>}
                                        <span className={styles.pro_price}>
                                            <CurrencyFormat value={Number(cartTotal).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={getCurrencySign(_domain().currency) + ' '} renderText={value => value} />
                                        </span>
                                    </p>
                                    <span className={styles.cartDropdown} style={cartOption ? { pointerEvents: 'none' } : {}} onClick={() => setCartOption(!cartOption)}>
                                        <i className="fa-thin fa-chevron-down"></i>
                                    </span>
                                    <ItemPrice type={'cart'} navigateTocart={navigateTocart} data={''} activeDelivery={activeDelivery} currency_sign={currency_sign} offer_text={''} previousPrice={''} />
                                </div>
                                <div className={styles.currency_section} onMouseOver={() => showMenuPopup('')}>
                                    <Currency />
                                </div>
                            </div>
                        </div>
                        <div className={style.mobileMenuButton} onClick={toggleMenu}>
                            <RxHamburgerMenu />
                        </div>
                        {isMenuOpen && (
                            <div ref={menuRef} className={style.mobile_menu_group}>
                                <div className={style.menu_mobile_container}>
                                    <div className={style.menu_title_mobile}>
                                        <Link to="/our-services" className={style.menu_label} onClick={() => setIsMenuOpen(false)}>
                                            <div className={`${style.menu_label} ${currentPath === '/develop' ? style.active : ''}`}> Services </div>
                                        </Link>
                                        <div className={style.mobileNavInnerIcon} onClick={() => handleExpand('develop')}><HiOutlinePlus className={expandedMenus['develop'] ? style.displayN : ''} /><HiOutlineMinus className={expandedMenus['develop'] ? style.displayB : style.displayN} /></div><br></br>
                                    </div>
                                    {expandedMenus['develop'] && (
                                        <div className={style.MobileNavExpanded}>
                                            <Link to="/our-services/online-shopping" onClick={() => setIsMenuOpen(false)} className={style.expanded_menu_option}>Online Retail of Quality Goods</Link>
                                            <Link to="/our-services/perishable-produce" onClick={() => setIsMenuOpen(false)} className={style.expanded_menu_option}>Import of Perishable Produce</Link>
                                            <Link to="/our-services/export-parts" onClick={() => setIsMenuOpen(false)} className={style.expanded_menu_option}>Export Of Car Parts</Link>
                                        </div>
                                    )}
                                </div>
                                <div className={style.menu_mobile_container}>
                                    <div className={style.menu_title_mobile}>
                                        <Link to="/migration" onClick={() => setIsMenuOpen(false)} className={style.menu_label}>
                                            <div className={`${style.menu_label} ${currentPath === '/career' ? style.active : ''}`}>Career</div>
                                        </Link>
                                        <Link to="/migration" onClick={() => { setIsMenuOpen(false); handleExpand('career') }} className={style.mobileNavInnerIcon}>
                                            <HiOutlinePlus style={{ opacity: '.3' }} />
                                        </Link>
                                    </div>
                                </div>
                                <div className={style.menu_mobile_container}>
                                    <div className={style.menu_title_mobile} >
                                        <Link to="/#" onClick={() => setIsMenuOpen(false)} className={style.menu_label}>
                                            <div className={`${style.menu_label} ${currentPath === '#' ? style.active : ''}`}>Company</div>
                                        </Link>
                                        <div className={style.mobileNavInnerIcon} onClick={() => handleExpand('company')}>
                                            <HiOutlinePlus className={expandedMenus['company'] ? style.displayN : ''} /><HiOutlineMinus className={expandedMenus['company'] ? style.displayB : style.displayN} />
                                            {/* {expandedMenus['company'] ? <HiOutlineMinus className={style.displayB} /> : <HiOutlinePlus className={style.displayN} />} */}
                                        </div>
                                    </div>
                                    {expandedMenus['company'] && (
                                        <div className={style.MobileNavExpanded}>
                                            <Link to="/company/about" onClick={() => setIsMenuOpen(false)} className={style.expanded_menu_option}>About Us</Link>
                                            <Link to="/company/our-procces" onClick={() => setIsMenuOpen(false)} className={style.expanded_menu_option}>Our Process</Link>
                                            <Link to="/company/how-we-work" onClick={() => setIsMenuOpen(false)} className={style.expanded_menu_option}>How We Work</Link>
                                        </div>
                                    )}
                                </div>

                                <div className={style.menu_mobile_container}>
                                    <div className={style.menu_title_mobile}>
                                        <Link to="/#" className={style.menu_label}>
                                            <div className={`${style.menu_label} ${currentPath === '/#' ? style.active : ''}`}>Industries</div>
                                        </Link>
                                        <div className={style.mobileNavInnerIcon} onClick={() => handleExpand('industry')}>
                                            <HiOutlinePlus className={expandedMenus['industry'] ? style.displayN : ''} /><HiOutlineMinus className={expandedMenus['industry'] ? style.displayB : style.displayN} />
                                        </div>
                                    </div>
                                    {expandedMenus['industry'] && (
                                        <div className={style.MobileNavExpanded}>
                                            <Link to={'/industries'} onClick={() => setIsMenuOpen(false)} className={style.expanded_menu_option}>Automotive Industry</Link>
                                            <Link to="/industries" onClick={() => setIsMenuOpen(false)} className={style.expanded_menu_option}>Food and Hospitality Industry</Link>
                                            <Link to="/industries" onClick={() => setIsMenuOpen(false)} className={style.expanded_menu_option}>Retail and E-Commerce</Link>
                                        </div>
                                    )}
                                </div>
                                <div className={style.menu_mobile_container}>
                                    <div className={style.menu_title_mobile}>
                                        <Link to="/contact-us" onClick={() => setIsMenuOpen(false)} className={style.menu_label}>
                                            <div className={`${style.menu_label} ${currentPath === '/career' ? style.active : ''}`}>Contact</div>
                                        </Link>
                                        <Link to="/contact-us" onClick={() => { setIsMenuOpen(false); handleExpand('career') }} className={style.mobileNavInnerIcon}>
                                            <HiOutlinePlus style={{ opacity: '.3' }} />
                                        </Link>
                                    </div>
                                </div>

                                <div className={classNames(style.menu_title_mobile)}><div className={style.menu_label}>Sign Out</div></div>
                            </div>

                        )}
                    </div>
                </div>
            </header>
            {/* {menu_popup === 'brands' && <Brands brands={brands} setMenu_popup={setMenu_popup} />} */}
            {/* {menu_popup === 'search' && <Search setMenu_popup={setMenu_popup} />} */}
        </>
    )
}

export default Header;












// <>
//     <header className={styles.mainHeader}>
//         {/* Desktop Header section Start */}
//         <div className={styles.head_container}>
//             <div className={styles.head_left}>
//                 <div className={styles.header_logo} onClick={gotoHome}>
//                     <p className={styles.logoTextL}>REAL</p>
//                     <FaGem className={styles.logoIcon} />
//                     <p className={styles.logoTextR}>BRAND</p>
//                 </div>
//                 <div className={styles.all_menu_section_left}>
//                     <p className={styles.menu_title} onMouseOver={() => showMenuPopup('search')}>
//                         <span className={styles.icon_style}><i className="fa-thin fa-magnifying-glass"></i></span>
//                         <span className={styles.menu_label}> SEARCH</span>
//                     </p>
//                 </div>
//                 <div className={styles.all_menu_section_right}>
//                     <Link className={styles.menu_title} onMouseOver={() => showMenuPopup('work')} onClick={(e) => { clickToNavigate(e, 'migration', ''); showMenuPopup('') }} to={"https://job.ukbd.app"}> <span className={styles.menu_label}> JOB/SPONSORSHIP</span></Link>

//                     {/* <div className={classNames(styles.menu_section, styles.trackingSection)} onMouseOver={() => showMenuPopup('')}>
//                         <p className={styles.menu_title}> <span className={styles.menu_label}> TRACK ORDER</span></p>
//                         <div className={styles.trackingPop}>
//                             <Orders orderList={orderList} from={'head'} setShowOrderList={setShowOrderList} />
//                         </div>
//                     </div> */}
//                     <div className={classNames(styles.checkout_box, 'cartBoxContainer')} onMouseOver={() => showMenuPopup('')}>
//                         <p onClick={() => navigateTocart('GBP')}>
//                             <IoBagSharp color="#00403e" size={16} style={{ position: 'relative', top: '-1px' }} />
//                             {totalUniqueItems > 0 && <span className={styles.pro_qty}>[{totalUniqueItems}]</span>}
//                             <span className={styles.pro_price}>
//                                 <CurrencyFormat value={Number(cartTotal).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={getCurrencySign(_domain().currency) + ' '} renderText={value => value} />
//                             </span>
//                         </p>
//                         <span className={styles.cartDropdown} style={cartOption ? { pointerEvents: 'none' } : {}} onClick={() => setCartOption(!cartOption)}>
//                             <i className="fa-thin fa-chevron-down"></i>
//                         </span>
//                         <ItemPrice type={'cart'} navigateTocart={navigateTocart} data={''} activeDelivery={activeDelivery} currency_sign={currency_sign} offer_text={''} previousPrice={''} />
//                     </div>
//                     <div className={styles.currency_section} onMouseOver={() => showMenuPopup('')}>
//                         <Currency />
//                     </div>

//                     <div className={styles.account_section} onMouseOver={() => showMenuPopup('')}>
//                         <UserHeadSection />
//                     </div>
//                 </div>
//             </div>
//         </div>
//         {/* Desktop Header section End */}
//         {/* Mobile Header section Start */}
//         <div className={styles.head_container + ' ' + styles.mobileHead}>
//             {!searchState &&
//                 <div className={styles.head_left}>
//                     <Link className={styles.m_logo} to={_domain().app}>
//                         <img src={_domain().logo} alt="logo" />
//                     </Link>
//                         <div className={styles.m_search} onClick={() => setSearchPopup(true)}>
//                             <i className="fa-thin fa-magnifying-glass"></i>
//                             <span className={styles.iconLabel}>Search</span>
//                         </div>
//                     {_domain().name !== 'job.ukbd.app' ?
//                         (location.pathname !== '/profile' && location.pathname.indexOf('/map') === -1) &&
//                         <div className={styles.checkout_box}>
//                             <p onClick={() => navigateTocart(activeDelivery.delivery_currency_code)}>
//                                 <IoBagSharp color="#ff0000" size={24} style={{ position: 'relative', top: '-3px' }} />
//                                 {totalUniqueItems > 0 && <span className={styles.pro_qty}>[{totalUniqueItems}]</span>}
//                                 <span className={styles.pro_price}>
//                                     <CurrencyFormat value={Number(cartTotal).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={getCurrencySign(_domain().currency) + ' '} renderText={value => value} />
//                                 </span>
//                             </p>
//                         </div>
//                         : null
//                     }
//                     <div className={styles.account_section}>
//                         <UserHeadSection />
//                     </div>
//                     <div className={styles.m_search} onClick={navigation}>
//                         <i className="fa-light fa-bars"></i>
//                         <span className={styles.iconLabel}>Menu</span>
//                     </div>
//                 </div>
//             }
//             {searchPopup &&
//                 <div className={styles.searchSuggestionPopup}>
//                     {searchLoader ?
//                         <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//                             <img style={{ width: '100px', }} src={loader} alt={'loader'} />
//                         </div>
//                         :
//                         searchedItemList.length > 0 ?
//                             searchedItemList.map((v, i) =>
//                                 <div className={styles.srItemHolder} key={'sri_' + i}>
//                                     <Link to={'/single-item/' + v._id} className={styles.srItem} onClick={() => setSearchValue('')}>
//                                         <div className={styles.sri_image}>
//                                             <img src={IMAGE_URL + v.image_url[0]} alt={'sri_img'} />
//                                         </div>
//                                         <div className={styles.sriDetails}>
//                                             <div className={styles.sri_name}><p>{v.name[0].toLowerCase()}</p></div>
//                                             <div className={styles.sri_price}>
//                                                 <CurrencyFormat value={v.view_price} displayType={'text'} thousandSeparator={true} prefix={_domain().currency + ' '} renderText={value => value} />
//                                             </div>
//                                         </div>
//                                     </Link>
//                                     <div className={styles.srItemAdd}>
//                                         <AddButton data={v} theme="search" />
//                                     </div>
//                                 </div>
//                             )
//                             : <p className={styles.notFound}>Item not found.</p>
//                     }
//                 </div>
//             }
//         </div>
//     </header>
//     {menu_popup === 'search' && <SearchSuggest setMenu_popup={setMenu_popup} />}
//     {menu_popup === 'uk_eu' && <UkEuPopup setMenu_popup={setMenu_popup} />}
//     {menu_popup === 'deshi' && <DeshiPopup setMenu_popup={setMenu_popup} />}
//     {menu_popup === 'travel' && <TravelPopup setMenu_popup={setMenu_popup} />}
//     {menu_popup === 'study' && <StudyPopup setMenu_popup={setMenu_popup} />}
//     {menu_popup === 'work' && <WorkPopup setMenu_popup={setMenu_popup} />}
//     {menu_popup === 'property' && <PropertyPopup setMenu_popup={setMenu_popup} />}
//     {menu_popup === 'money' && <MoneyPopup setMenu_popup={setMenu_popup} />}

// </>