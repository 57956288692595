import React, { useEffect } from 'react'
import styles from '../../assets/css/Migration.module.css';
import students from '../../assets/Students.png';
import classNames from 'classnames';
import { useState } from 'react';
import Select from 'react-select';
import { add_new_job_application, get_item_from_product } from '../../Utils/method';
import flag_bd from '../../assets/flag-BD.png';
import OTPVerification from './OTPVerification';
import Message from './Message';
import Documents from './Documents';
import Jobs from './Jobs';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import pdf from '../../assets/pdf.svg'
import JobApplication from './JobApplication';
import Loader from '../Loader';
import carrer from '../../assets/hnbrothers/career001.webp'

const selectStyles = {
    control: (base) => ({
        ...base,
        fontSize: '14px',
        boxShadow: 'none',
        '&:focus': {
            border: '0 !important',
        },
        border: 'none',
        background: 'transparent',
        minHeight: '18px'
    }),
    placeholder: (baseStyles, state) => ({
        ...baseStyles,
        color: 'rgba(0,48,25,.1)',
    })
    ,
    options: (baseStyles) => ({
        ...baseStyles,
        borderRadius: 0,
        padding: '1px 15px',
        width: '100%',
        borderBottom: '1px solid rgba(0,48,25,1)'
    })
    ,
    menu: (baseStyles) => ({
        ...baseStyles,
        borderRadius: 0,
        height: 'auto',
        width: '100%',
        right: '0px',
        top: '13px',

    })
    ,
    multiValue: (base) => ({
        ...base,
        background: '#ced4da',
        borderRadius: '0px',
        marginTop: '0px',
        fontWeight: '400',
        lineHeight: '18px',
        position: 'relative',
        top: '-2px',
        left: '-10px'
    }),
    singleValue: (baseStyles) => ({
        ...baseStyles,
        color: 'rgba(0,48,25,.9)',
    })
}

const IeltsOptions = [
    { value: '4.0', label: '4.0' },
    { value: '4.5', label: '4.5' },
    { value: '5.0', label: '5.0' },
    { value: '5.5', label: '5.5' },
    { value: '6.0', label: '6.0' },
    { value: '6.5', label: '6.5' },
    { value: '7.0', label: '7.0' },
    { value: '7.5', label: '7.5' },
    { value: '8.0', label: '8.0' },
    { value: '8.5', label: '8.5' },
    { value: '9.0', label: '9.0' },
    { value: 'Not Available', label: 'Not Available' },
]

const SalaryOptions = [
    { value: `BDT ${(200000).toLocaleString('en-IN')} - BDT ${(300000).toLocaleString('en-IN')}`, label: `BDT ${(200000).toLocaleString('en-IN')} - BDT ${(300000).toLocaleString('en-IN')}` },
    { value: `BDT ${(300000).toLocaleString('en-IN')} - BDT ${(500000).toLocaleString('en-IN')}`, label: `BDT ${(300000).toLocaleString('en-IN')} - BDT ${(500000).toLocaleString('en-IN')}` },
    { value: `BDT ${(500000).toLocaleString('en-IN')} - BDT ${(700000).toLocaleString('en-IN')}`, label: `BDT ${(500000).toLocaleString('en-IN')} - BDT ${(700000).toLocaleString('en-IN')}` },
    { value: `BDT ${(700000).toLocaleString('en-IN')} - BDT ${(1000000).toLocaleString('en-IN')}`, label: `BDT ${(700000).toLocaleString('en-IN')} - BDT ${(1000000).toLocaleString('en-IN')}` },
    { value: 'other', label: 'Other' },
]

const getCurrentDomain = () => {
    const hostname = window.location.hostname;
    const domainParts = hostname.split('.');
    // console.log('domainParts:', domainParts[0]);
    return domainParts[0];
};

const currentDomain = getCurrentDomain();

function Migration() {
    const [loader, setLoader] = useState(false)
    const [all_jobs, setAll_jobs] = useState([]);
    const [expand, setExpand] = useState([])
    const [current_job, setCurrent_job] = useState(null);
    const [job_application, setJob_application] = useState(false);

    useEffect(() => {
        let _isMounted = true;

        async function init() {
            setLoader(true)
            try {
                let apires = await get_item_from_product({ product_id: '64e5c601dbc86d3553601259' })
                if (_isMounted) {
                    console.log('get_item_from_product:', apires.items);
                    const reversedArray = apires.items.slice().reverse();
                    setAll_jobs(reversedArray)
                }
                setLoader(false)
            } catch (error) {
                setLoader(false)
                console.log(error);
            }
        }
        init()

        return () => {
            _isMounted = false
        }
    }, [])

    const manageExpand = (id) => {
        if (expand.indexOf(id) > -1) {
            setExpand(prev => prev.filter(f => f !== id))
        } else {
            setExpand(prev => [...prev, id])
        }
    }

    const applyFun = (d) => {
        console.log(d);
        setCurrent_job(d)
        setJob_application(true)
    }

    const format_date = (d) => {
        const date = new Date(d);
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        return formattedDate;
    }

    const compare_date = (d) => {
        const targetDate = new Date(d);
        const currentDate = new Date();

        if (currentDate < targetDate) {
            console.log(11);
            return true
        } else if (currentDate > targetDate) {
            console.log(22);
            return false
        } else {
            console.log(33);
            return true
        }
    }
    const inputs = document.querySelectorAll('input[type="text"], input[type="number"]');

    inputs.forEach(function (input) {
        input.addEventListener('input', function () {
            const inputValue = input.value.trim();
            if (inputValue !== '') {
                input.style.backgroundColor = '#F5FAFF';
            } else {
                input.style.backgroundColor = '#fff';
            }
        });
    });
    const gotoTop = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'end' })
        }
    }

    return (
        <div className={styles.mainContainer}>
            <ToastContainer />
            <div className={styles.leftContainer}>
                <img className={styles.migrationLeftImage} src={carrer} alt='career' />
            </div>
            <div className={styles.rightContainer} id='rightContainer'>
                {<Link to={'/migration/jobs'} className={styles.fullScreenView}>Full screen view <span><i className="fa-light fa-arrow-up-right-and-arrow-down-left-from-center"></i></span></Link>}
                <div className={styles.rightContainerInner}>
                    <div className={styles.rightTopText}>
                        <p className={styles.rightTopinnertext}>
                            Join with us to find your dream JOB
                        </p>
                    </div>
                    <div className={styles.jobContainer}>
                        {loader ? <Loader />
                            :
                            all_jobs.map(v =>
                                <div className={styles.expandArea} key={v._id}>
                                    <div className={styles.expandAreaHead} onClick={() => manageExpand(v._id)} style={expand.indexOf(v._id) > -1 ? { backgroundColor: '#e5eae8' } : {}}>
                                        <div className={styles.exp_title}>
                                            <p className={styles.mainTitle}>{v.name.toString()} </p>
                                            <div className={styles.exp_title_right}>
                                                <p className={styles.price_range} style={{ marginBottom: '5px' }}>{'£ ' + Number(v.salary_range_start).toLocaleString()} {v.salary_time === 'Per Annum' ? 'pa' : v.salary_time} ({v.location})</p>
                                                {/* {v.salary_range_end === '' || v.salary_range_end === 0 || v.salary_range_end === '0' || v.salary_range_end === '0.00' ?
                                                    <p className={styles.price_range} style={{ marginBottom: '5px' }}>{'£ ' + Number(v.salary_range_start).toLocaleString()} {v.salary_time} ({v.location})</p>
                                                    :
                                                    <p className={styles.price_range} style={{ marginBottom: '5px' }}>{'£ ' + Number(v.salary_range_start).toLocaleString()} to {'£ ' + Number(v.salary_range_end).toLocaleString()} {v.salary_time} ({v.location})</p>
                                                } */}
                                                <p className={styles.price_range}>Deadline: {format_date(v.deadline)}</p>
                                            </div>
                                        </div>
                                        <div className={styles.exp_icon}>
                                            {expand.indexOf(v._id) > -1 ? <i className="fa-light fa-minus"></i> : <i className="fa-light fa-plus"></i>}
                                        </div>
                                    </div>
                                    {expand.indexOf(v._id) > -1 &&
                                        <div className={styles.expandAreaBody}>
                                            <div className={styles.apply_section}>
                                                <button className={styles.apply_form_button} style={compare_date(v.deadline) ? {} : { pointerEvents: 'none', opacity: '0.5' }} onClick={() => applyFun(v)}>APPLY NOW</button>
                                            </div>
                                            {/* <div className={styles.intro_section}>
                                                <p className={styles.intro_item}> Published:  August 2023 </p>
                                                <p className={styles.intro_item}> Location: Bedfordshire, United Kingdom </p>
                                                <p className={styles.intro_item}> Category: IT Services </p>
                                                <p className={styles.intro_item}> Job Type:  Full-time, Permanent </p>
                                                <p className={styles.intro_item}> Salary:  From £ 45,000 To £ 60,000 Per Annum </p>
                                            </div>
                                            <p className={styles.para_title}>DESCRIPTION</p> */}
                                            <div className={styles.exp_para} dangerouslySetInnerHTML={{ __html: v.details }} />
                                            <div className={styles.apply_section}>
                                                <button className={styles.apply_form_button} style={compare_date(v.deadline) ? {} : { pointerEvents: 'none', opacity: '0.5' }} onClick={() => applyFun(v)}>APPLY NOW</button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            )}
                    </div>
                </div>
            </div>
            <div className={styles.line1_and_line2_container}>
                <div className={styles.line1_vertical}></div>
                <div className={styles.line2_vertical}></div>
                <div className={styles.line_cursor_box} onClick={() => gotoTop('rightContainer')}></div>
            </div>

            {job_application && <JobApplication current_job={current_job} setJob_application={setJob_application} />}
        </div >
    )
}

export default Migration;
