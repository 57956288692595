import styles from './footer.module.css'
import { useEffect, useState } from 'react';
import { get_single_forms, submitPublicForm, get_domain_by_name } from '../../../Utils/method';
import { IMAGE_URL } from '../../../Config/Config';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { toast } from 'react-hot-toast';
import { RxCross1 } from "react-icons/rx";
import ConImage from '../../../assets/fs2.webp'


export default function ContactUS() {

    const [formTitle, setFormTitle] = useState('')
    const [loader, setLoader] = useState(false)
    const [DynamicFields, setDynamicFields] = useState([])
    const [thankYouText, setThankYouText] = useState(false)

    const isEmpty = (value) => {
        if (value !== '') {
            return false;
        } else {
            return true
        }
    }

    // const getSingleForm = async () => {
    //     let data = await get_single_forms({ form_id: '2401030002' });
    //     console.log("🚀 ~ file: footer.js:12 ~ getSingleForm ~ data:", data)
    //     if (data.success) {           
    //         setFormTitle(data.data.form_title)
    //         setDynamicFields(data.data.dynamic_fields.sort((a, b) => a.sl > b.sl ? 1 : -1))
    //     }
    // }
    const getSingleForm = async () => {
        try {
            let data = await get_single_forms({ form_id: '2411170015' });
            console.log("🚀 ~ file: footer.js:12 ~ getSingleForm ~ data:", data);

            if (data && data.success && data.data) {
                setFormTitle(data.data.form_title);
                setDynamicFields(data.data.dynamic_fields.sort((a, b) => a.sl > b.sl ? 1 : -1));
            } else {
                console.error("Data is invalid or success flag is false:", data);
            }
        } catch (error) {
            console.error("Error fetching form data:", error);
        }
    };


    useEffect(() => {
        getSingleForm()
    }, [])

    const saveForm = async (type) => {
        if (!isEmpty(DynamicFields[0].value)) {
            let fields = []
            for (let i = 0; i < DynamicFields.length; i++) {
                let obj = DynamicFields[i]
                if (obj.label !== '' && obj.type !== '') {
                    obj['name'] = obj.label.replace(/ /g, '_').toLowerCase();
                    delete obj['instance_image'];
                    if (obj.type === 'select') {
                        let options = []
                        for (let j = 0; j < obj.options.length; j++) {
                            const option = obj.options[j];
                            if (option.label !== '' && option.value !== '') {
                                options.push(option)
                            }
                        }
                        obj['options'] = options;
                        fields.push(obj)
                    } else {
                        fields.push(obj)
                    }
                }
            }
            try {
                setLoader(true)
                let publicdata = await submitPublicForm({
                    form_id: '2401030002',
                    form_title: formTitle,
                    dynamic_fields: JSON.stringify(fields.map(v => { return ({ ...v, instance_image: '' }) }))

                });
                setThankYouText(true);

                console.log(123, publicdata)

                toast.success("Thank you for reaching out! We've received your message and will get back to you as soon as possible.", {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                setLoader(false)
            } catch (error) {
                console.log(error)
                toast.error(error.error_description, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                setLoader(false)
            }
        }
    }

    const [changeCount, setChangeCount] = useState(0);

    function isValidEmail(email) {
        // Regular expression for a basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    const handleFieldsData = async (e, fi, oi, type) => {
        setChangeCount((v) => (v + 1));
        const value = oi.type === 'phone' ? e : e.target.value;
        if (type === 'value') {
            let status = false;
            if (!isEmpty(value)) {
                if (oi.type == 'email') {
                    if (isValidEmail(value)) {
                        status = true;
                    }
                } else {
                    status = true;
                }
            }
            setDynamicFields([...DynamicFields.map((v, j) => fi === j ? { ...v, value: value } : v)])
        }

    }




    // Image API fetch from partner domain
    const [domain_data, setDomain_data] = useState(null);
    const get_domain_data = async () => {
        try {
            let apires = await get_domain_by_name(`?name=${'hnbrothers.uk'}`)
            console.log('get_domain_by_name_home_page:', apires.domain);
            console.log('background_img_url:', apires.domain.homepage_product_preview);
            localStorage.setItem('_domain', JSON.stringify(apires.domain))
            setDomain_data(apires.domain)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        get_domain_data()
    }, []);

    const contact_image = domain_data?.additional_fields.find(field => field.id === 'FID_40').image;
    const contact_fullImageUrl = `${IMAGE_URL}${contact_image}`;



    const imageWidth = 800;
    const imageHeight = 600;

    const goBack = () => {
        window.history.back();
    }

    return (
        <>
            <div className={styles.main_container} id='contact-us'>
                <div className={styles.main_section}>
                    <div className={styles.mega_menu_close_icon} onClick={goBack}> <RxCross1 style={{ cursor: 'pointer' }} title='Close' /> </div>
                    <div className={styles.main_left}>
                        <img className={styles.main_left_img} src={ConImage} unoptimized priority alt='' width={imageWidth} height={imageHeight} ></img>
                        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1231.3006781305764!2d-0.4300524651412549!3d51.886488962405146!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876492c8aa4fe57%3A0x562d55f7c12de36e!2sNoor%20Mobiles%20Luton!5e0!3m2!1sen!2sbd!4v1704832232884!5m2!1sen!2sbd" className={styles.map_style}></iframe> */}
                    </div>
                    <div className={styles.main_right}>
                        <div className={styles.main_right_section}>
                            {thankYouText && <div className={styles.thankYouText}>Thank you for reaching out! We have received your message and will get back to you as soon as possible.</div>}
                            <div className={styles.main_section_title}>Contact Us</div>
                            <div className={styles.main_section_content}>
                                <div className={styles.footer_form}>
                                    {DynamicFields.map((v, i) =>
                                        (v.type !== 'radio' && v.type !== 'file' && v.type !== 'select' && v.type !== 'textarea' && v.type !== 'phone') ?
                                            <div className={styles.footer_form_section} key={i}>
                                                <label>{v.label}: *</label> <br />
                                                <input type={v.type} className={styles.inputs} placeholder={v.placeholder} onChange={(e) => handleFieldsData(e, i, v, 'value')} value={v.value} />
                                            </div>
                                            : v.type === 'phone' ?
                                                <div className={styles.footer_form_section} key={i}>
                                                    <label>{v.label}: *</label> <br />
                                                    <PhoneInput
                                                        country={'gb'}
                                                        inputProps={{
                                                            required: true,
                                                            autoFocus: false
                                                        }}
                                                        inputClass={styles.phone_inputs}
                                                        buttonClass={styles.phone_btn}
                                                        enableSearch
                                                        placeholder={v.placeholder}
                                                        value={v.value}
                                                        onChange={phone => handleFieldsData(phone, i, v, 'value')}
                                                    />
                                                </div>
                                                : v.type === 'textarea' ?
                                                    <div className={styles.footer_form_section} key={i}>
                                                        <label>{v.label}: *</label> <br />
                                                        <textarea className={styles.text_area} placeholder={v.placeholder} onChange={(e) => handleFieldsData(e, i, v, 'value')} value={v.value} />
                                                    </div>
                                                    : null
                                    )}
                                    {/* <div className={styles.footer_form_section}>
                                        <label>Name: *</label> <br />
                                        <input type="text" id="name" required />
                                    </div>

                                    <div className={styles.footer_form_section}>
                                        <label>Phone: *</label> <br />
                                        <input type="text" id="name" required />
                                    </div> */}

                                    {/* <div className={styles.footer_form_section}>
                                        <label>Email: *</label> <br />
                                        <input type="email" id="name" required />
                                    </div> */}

                                    {/* <div className={styles.footer_form_section}>
                                        <label>Message:</label> <br />
                                        <textarea type="text" id="name" required />
                                    </div> */}

                                    <div className={styles.footer_form_section}>
                                        <div className={styles.footer_form_submit} onClick={saveForm}>
                                            <button onClick={saveForm}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className={styles.footer_copyright}>
                            <Link href="/terms-and-conditions" className={styles.footer_copyright_item}>Terms and Conditions</Link>
                            <Link href="/privacy-policy" className={styles.footer_copyright_item}>Privacy Policy</Link>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
}

















// import React from "react";
// import { useSelector } from 'react-redux';
// import styles from '../../../assets/css/ContactUs.module.css';
// import { useNavigate } from "react-router-dom";
// function ContactUs() {
//     const navigate = useNavigate();
//     const contact_us = useSelector((state) => state.rootReducer.website_setting.contact_us)
//     return (
//         <div className={styles.ContactUsContainer}>
//             <span onClick={() => navigate(-1)} className={styles.closeCart}><i className="fa-thin fa-xmark"></i></span>
//             <div className={styles.contactUs} dangerouslySetInnerHTML={{ __html: contact_us }}></div>
//         </div>
//     )
// }

// export default ContactUs;