'use client'
import React, { useRef } from 'react'
import styles from '../develop.module.css'

import produce1 from '../../../assets/hnbrothers/fd2.jpg'
import produce2 from '../../../assets/hnbrothers/fd1.jpg'
import produce3 from '../../../assets/hnbrothers/fd3.jpg'
import s4 from '../../../assets/hnbrothers/SERVICES004.webp'
import { Link } from 'react-router-dom'



export default function PerishableFood() {
    let currentIndex = 0;


    const slide_down_1 = () => {
        const allMainContainers = document.querySelectorAll('.main');

        if (allMainContainers.length > 0) {
            currentIndex = (currentIndex + 1) % allMainContainers.length;
            const nextContainer = allMainContainers[currentIndex];
            nextContainer.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };


    return (
        <div className={styles.landing} id="main_content_div" >
            <div className={`${styles.main_container} main`}>
                <div className={styles.main_template}>
                    <div className={styles.main_section}>
                        <div className={styles.mainContentBody}>
                            <div className={styles.mainImages}>
                                <div className={styles.imageSideM}>
                                    <img className={styles.main_img} src={produce1} unoptimized priority alt='' />
                                </div>
                            </div>
                            <div className={styles.mainContent}>
                                <div className={styles.mainHead}>
                                    <p className={styles.headingMain}><span>High-Quality Fresh Produce</span></p>
                                </div>
                                <p className={styles.headingMain}>
                                    Fruits, Vegetables, Herbs, <br />
                                    and More.
                                </p>
                                <p className={styles.bodyContentP} style={{ marginBottom: '0px' }}>
                                    Our perishable produce selection is sourced from trusted farms and suppliers, ensuring freshness and top quality with every item. From exotic fruits to everyday vegetables, our collection brings the finest flavors to your table.
                                </p>
                                <p className={styles.bodyContentP} style={{ marginBottom: '0px' }}>
                                    We prioritize organic and sustainable growing practices, giving you products that are as good for your health as they are for the environment. Our fruits and vegetables are free from harmful pesticides and chemicals, ensuring safe consumption for you and your family.
                                </p>
                                <p className={styles.bodyContentP}>
                                    Enjoy the taste of seasonal produce picked at its peak ripeness. Our careful selection guarantees not only superior flavor but also essential nutrients, promoting a healthy lifestyle.
                                </p>
                                <div className={styles.developButtonContainer}>
                                    <div className={styles.developButtonBottom} onClick={slide_down_1}>Discover More</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${styles.main_container} main`}>
                <div className={styles.main_template}>
                    <div className={styles.main_section}>
                        <div className={styles.mainContentBody}>
                            <div className={styles.mainContent}>
                                <div className={styles.mainHead}>
                                    <p className={styles.headingMain}>Sourced from Top Regions</p>
                                </div>
                                <p className={styles.bodyContentP}>
                                    Our produce comes from regions known for their fertile lands and ideal climates, including Brazil, Morocco, and Portugal. These areas provide natural advantages that enhance the taste and quality of each item.
                                </p>
                                <p className={styles.bodyContentP}>
                                    We work directly with local farms and suppliers, supporting agricultural communities while ensuring transparency and quality control at every step of the journey. By choosing our produce, you support local economies and sustainable farming practices.
                                </p>
                                <p className={styles.bodyContentP}>
                                    Our commitment to traceability means you can trust where your food comes from. Each product is carefully tracked from farm to table, providing peace of mind in every bite.
                                </p>
                                <div className={styles.developButtonContainer}>
                                    <div className={styles.developButtonBottom} onClick={slide_down_1}>Discover More</div>
                                </div>
                            </div>
                            <div className={styles.mainImages}>
                                <div className={styles.imageSideM}>
                                    <img className={styles.main_img} src={produce2} unoptimized priority alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${styles.main_container} main`}>
                <div className={styles.main_template}>
                    <div className={styles.main_section}>
                        <div className={styles.mainContentBody}>
                            <div className={styles.mainImages}>
                                <div className={styles.imageSideM}>
                                    <img className={styles.main_img} src={produce3} unoptimized priority alt='' />
                                </div>
                            </div>
                            <div className={styles.mainContent}>
                                <div className={styles.mainHead}>
                                    <p className={styles.headingMain}>Guaranteed Freshness and Fast Delivery</p>
                                </div>
                                <p className={styles.bodyContentP}>
                                    To ensure maximum freshness, our produce is carefully stored and shipped with fast delivery options. Each item is packaged to maintain its quality, so it arrives at your door just as fresh as it was picked.
                                </p>
                                <p className={styles.bodyContentP}>
                                    With a commitment to excellence, we guarantee a seamless shopping experience, making it easier for you to bring the best produce into your home. Our dedicated team ensures that your order is handled with care from the moment you place it until it arrives on your doorstep.
                                </p>
                                <p className={styles.bodyContentP}>
                                    We offer various delivery options, including same-day delivery in select areas, so you never have to wait long to enjoy your fresh produce. Experience the convenience of farm-fresh fruits and vegetables delivered straight to you!
                                </p>
                                <Link to={'/contact-us'} className={styles.developButtonContainer}>
                                    <div className={styles.developButtonBottom}>Get in touch</div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}