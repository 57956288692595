import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from '../assets/css/BannerSection.module.css'
import { IMAGE_URL } from '../Config/Config';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { _domain } from '../Config/Domains';
import HN_logo from '../assets/hnBrother.png'
import { FaGem } from "react-icons/fa";
import HnLogo from '../assets/hnBrother.png'
import { Link } from 'react-router-dom';
import ecom from '../assets/hnbrothers/SLIDE005.webp'
import ecom2 from '../assets/hnbrothers/SLIDE003.webp'
import ecom3 from '../assets/hnbrothers/SLIDE007.webp'
import { BsArrowRight } from "react-icons/bs";




function BannerSection() {
    const domain_data = localStorage.getItem('_domain') ? JSON.parse(localStorage.getItem('_domain')) : null
    const [Banner, setBanner] = useState([])

    useEffect(() => {
        setBanner(domain_data ? domain_data.home_banner_images : [])
    }, [])

    const settings = {
        autoplay: true,
        autoplaySpeed: 7000,
        cssEase: 'linear',
        pauseOnHover: true,
        dots: false,
        dotsClass: "slick-dots slick-thumb",
        className: styles.bannerSlideItem,
        infinite: true,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <>
            <div className={styles.bannerSection}>
                <div className={classNames('bannerSlider', styles.bannerSliderSection)}>
                    <Slider {...settings}>
                        {Banner.map(i =>
                            <img className={styles.sliderImg} key={i.key + '_k'} src={IMAGE_URL + i.path} alt='' />
                        )}
                    </Slider>
                </div>
                <video id="background-video" autoPlay loop muted playsInline>
                    <source src="/videos/vu.mp4" type="video/mp4" />
                </video>
                <div className={styles.left_side} style={{ backgroundColor: 'rgb(255 255 255 / 85%)' }}>
                    <div className={styles.product_description} style={{ flexDirection: 'column' }}>
                        <div className={styles.mainTopContainer}>
                            <img src={HnLogo} className={styles.newLogo} alt='' />
                        </div>
                        <div className={styles.mainMidContainer}>
                            <p>Excellence in Every Choice</p>
                        </div>
                        <div className={styles.mainBottomText}>
                            <p className={styles.welcome_text} >Welcome to <strong>HN Brothers Ltd!</strong> <br></br>
                                <span className={styles.mobileMottomText} style={{ fontSize: '16px' }}>We are delighted to have you with us. Discover how our dedication to quality and customer satisfaction can enhance your experience. Thank you for choosing us as your trusted partner.</span></p>
                            <div className={styles.container_Button}><p>
                                <Link to={'/our-services'} className={styles.navButtonsBottom}>Learn More </Link>
                            </p></div>
                        </div>
                    </div>
                    {/* {(device && (device === "web")) ?
                            <>
                                <Double_Scroll_client_arefin goToTop={scrollToTop} showTopBtn={showTopBtn_State} />
                                <Scroll_testing_client_arefin slide_up_by_1={slide_up_1} slide_down_by_1={slide_down_1} wanting_to_scroll_Bottom={wanting_to_scroll_Bottom_State} />
                            </>
                            : null
                        } */}
                </div>
            </div>
            <div className={styles.bannerSection}>
                <div className={styles.product_item}>
                    <div className={styles.service_container}>
                        <div className={styles.service_inner_container}>
                            <div className={styles.service_content_section}>
                                <div className={styles.service_item_section}>
                                    <Link to={'/shop'} className={styles.service_item} style={{ borderRight: 'none' }}>
                                        <div className={styles.service_title}>Online Shopping</div>
                                        <img className={styles.service_Image} src={ecom} alt='ecommerce' />
                                        <div className={styles.service_content}>Explore a curated range of luxury watches, cosmetics, and makeup through our online store, BuyRealBrands. Experience quality and elegance with every purchase.</div>
                                        <div className={styles.service_tell_me_more_section}><Link href='/our-services/online-shopping'> more </Link> <BsArrowRight /> </div>
                                    </Link>
                                    <Link to={'/our-services/perishable-produce'} className={styles.service_item}>
                                        <div className={styles.service_title} >Perishable Produce</div>
                                        <img className={styles.service_Image} src={ecom2} alt='ecommerce' />
                                        <div className={styles.service_content}> Enjoy premium, Perishable Produce delivered with care. Our selection meets the highest standards, ensuring your business or kitchen receives only the best.</div>
                                        <div className={styles.service_tell_me_more_section}> <Link href='/our-services/perishable-produce'> more  </Link> <BsArrowRight />  </div>
                                    </Link>
                                    <Link to={'/our-services/export-parts'} className={styles.service_item}>
                                        <div className={styles.service_title}>Car Parts</div>
                                        <img className={styles.service_Image} src={ecom3} alt='ecommerce' />
                                        <div className={styles.service_content}>Discover our reliable and durable used car parts, designed to keep your vehicle running smoothly. We ensure quality and affordability for all your automotive needs.</div>
                                        <div className={styles.service_tell_me_more_section}><Link href='/our-services/export-parts'> more </Link> <BsArrowRight /> </div>
                                    </Link>
                                </div>
                                <div className={styles.service_all_btn_section}>
                                    <Link to='/our-services' className={styles.btn_item}>See all services <BsArrowRight /> </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BannerSection