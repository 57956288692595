const RouteList = {
    "home": "/",
    "shop": "/shop",
    "store_sign_in": "/store/sign-in",
    "store_sign_up": "/store/sign-up",
    "driver_sign_in": "/driver/sign-in",
    "driver_sign_up": "/driver/sign-up",
    "order_track": "/map/track-order/:_id",
    "sign_in": "/sign-in",
    "sign_in_otp": "/sign-in-with-otp",
    "logout": "/logout",
    "sign_up": "/sign-up",
    "forgot_password": "/forgot-password",
    "popular_products": "/popular-products",
    "cart": "/cart",
    "checkout": "/checkout",
    "payment": "/payment",
    "payment_success": "/payment-success",
    "profile": "/profile",
    "product_single": "/product/:_id",
    "FAQs": "/FAQs",
    "contact_us": "/contact-us",
    "terms_conditions": "/terms-conditions",
    "privacy_policy": "/privacy-policy",
    "single_item": "/single-item/:_id",
    "deshi_single_item": "/deshi/single-item/:_id",
    "category": "/category/:_id",
    "dashboard": "/dashboard",
    "ukbd": "/ukbd",
    // "deshi":"/deshi",
    "migration": "/migration",
    "sponsorship_application": "/migration/sponsorship-application",
    "jobs": "/migration/jobs",
    // "job_application":"/migration/jobs/job-application",
    "travel": "/travel",
    "study": "/study",
    "property": "/property",
    "application": "/application",
    "our_services": "/our-services",
    "export_parts": "/our-services/export-parts",
    "online_shopping": "/our-services/online-shopping",
    "perishable_produce": "/our-services/perishable-produce",
    "industries": "/industries",
    "how_we_work": "/company/how-we-work",
    "ourProcces": "/company/our-procces",
    "about": "/company/about",
    "terms": "/terms-and-conditions",
    "privacy": "/privacy-policy",
}

export default RouteList;