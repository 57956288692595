import React from "react";
import UKBD from "../assets/UKBD.png"
import RealBrand from '../assets/RealBrand.png'
import USBD from "../assets/USBD.jpg"
import JPBD from "../assets/JPBD.jpg"
import { _domain } from "../Config/Domains";
import HnLogo from '../assets/hnBrother.png'

function HomeLoader() {
    return (
        <div className="loader_ico home" style={{ height: '100vh' }}>
            <div>
                <img src={HnLogo} style={{ height: '100px', width: 'auto' }}
                    alt={"loader"} className={"zoom-in-out-box"} />
            </div>
        </ div>
    )
}
export default HomeLoader;