'use client'
import React, { useRef } from 'react'
import styles from '../develop.module.css'
import { useState, useEffect } from 'react';
import carParts1 from '../../../assets/hnbrothers/ep1.jpg'
import carParts2 from '../../../assets/hnbrothers/ep2.jpg'
import carParts3 from '../../../assets/hnbrothers/ep3.jpg'
import { Link } from 'react-router-dom';


export default function ExportParts() {
    let currentIndex = 0;

    const slide_down_1 = () => {
        const allMainContainers = document.querySelectorAll('.main');

        if (allMainContainers.length > 0) {
            currentIndex = (currentIndex + 1) % allMainContainers.length;
            const nextContainer = allMainContainers[currentIndex];
            nextContainer.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <div className={styles.landing} id="main_content_div" >
            <div className={`${styles.main_container} main`}>
                <div className={styles.main_template}>
                    <div className={styles.main_section}>
                        <div className={styles.mainContentBody}>
                            <div className={styles.mainImages}>
                                <div className={styles.imageSideM}>
                                    <img className={styles.main_img} src={carParts1} unoptimized priority alt='Exported Car Parts' />
                                </div>
                            </div>
                            <div className={styles.mainContent}>
                                <div className={styles.mainHead}>
                                    <p className={styles.headingMain}><span>Export of Quality Car Parts</span></p>
                                </div>
                                <p className={styles.headingMain}>
                                    Your Reliable Partner in Global Automotive Supply.
                                </p>
                                <p className={styles.bodyContentP} style={{ marginBottom: '0px' }}>
                                    At HN Brothers Ltd, we specialize in the export of high-quality car parts, catering to various international markets. Our extensive inventory includes components for a wide range of vehicle makes and models, ensuring that we meet the diverse needs of our clients worldwide.
                                </p>
                                <p className={styles.bodyContentP}>
                                    With years of experience in the automotive industry, we understand the importance of quality and reliability. Our products undergo rigorous testing and quality checks to ensure they meet international standards before being shipped to our clients. We take pride in our commitment to excellence, offering a warranty on select items to give our customers peace of mind.
                                </p>
                                <div className={styles.developButtonContainer}>
                                    <div className={styles.developButtonBottom} onClick={slide_down_1}>Discover More</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${styles.main_container} main`}>
                <div className={styles.main_template}>
                    <div className={styles.main_section}>
                        <div className={styles.mainContentBody}>
                            <div className={styles.mainContent}>
                                <div className={styles.mainHead}>
                                    <p className={styles.headingMain}>Comprehensive Range of Parts</p>
                                </div>
                                <p className={styles.bodyContentP}>
                                    We export a wide array of car parts, including:
                                </p>
                                <ul className={styles.bodyContentP}>
                                    <li>Engine components</li>
                                    <li>Transmission parts</li>
                                    <li>Braking systems</li>
                                    <li>Suspension systems</li>
                                    <li>Electrical components</li>
                                    <li>Body parts and accessories</li>
                                </ul>
                                <p className={styles.bodyContentP}>
                                    Our commitment to quality ensures that every part we export is durable, efficient, and meets the specifications required for optimal vehicle performance. We also stay up-to-date with the latest technological advancements in the automotive industry, constantly expanding our inventory to include innovative parts and accessories.
                                </p>
                                <p className={styles.bodyContentP}>
                                    Whether you are looking for original equipment manufacturer (OEM) parts or high-quality aftermarket options, we have the solutions you need to keep your vehicles running smoothly.
                                </p>
                                <div className={styles.developButtonContainer}>
                                    <div className={styles.developButtonBottom} onClick={slide_down_1}>Discover more</div>
                                </div>
                            </div>
                            <div className={styles.mainImages}>
                                <div className={styles.imageSideM}>
                                    <img className={styles.main_img} src={carParts2} unoptimized priority alt='Car Parts Export' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${styles.main_container} main`}>
                <div className={styles.main_template}>
                    <div className={styles.main_section}>
                        <div className={styles.mainContentBody}>
                            <div className={styles.mainImages}>
                                <div className={styles.imageSideM}>
                                    <img className={styles.main_img} src={carParts3} unoptimized priority alt='Global Distribution of Car Parts' />
                                </div>
                            </div>
                            <div className={styles.mainContent}>
                                <div className={styles.mainHead}>
                                    <p className={styles.headingMain}>Global Distribution Network</p>
                                </div>
                                <p className={styles.bodyContentP}>
                                    Our established global distribution network allows us to efficiently deliver car parts to customers around the world. We work closely with reliable logistics partners to ensure timely shipping and handling, reducing lead times and ensuring customer satisfaction.
                                </p>
                                <p className={styles.bodyContentP}>
                                    Whether {`you're`} a retailer, mechanic, or automotive business, we provide tailored export solutions to meet your specific needs, ensuring you receive the right parts at the right time. Our dedicated customer service team is available to assist you with order tracking, shipping inquiries, and any other support you may need.
                                </p>
                                <p className={styles.bodyContentP}>
                                    We also offer flexible payment options and competitive pricing to make your purchasing experience as seamless as possible, allowing you to focus on growing your business while we take care of the logistics.
                                </p>
                                <Link to='/contact-us' className={styles.developButtonContainer}>
                                    <div className={styles.developButtonBottom} >Get in Touch</div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}