'use client'
import React, { useRef } from 'react'
import styles from '../develop.module.css'
import s1 from '../../../assets/hnbrothers/os1.jpg'
import s2 from '../../../assets/hnbrothers/os3.jpg'
import s3 from '../../../assets/hnbrothers/os2.jpg'
import s4 from '../../../assets/hnbrothers/SERVICES004.webp'
import { Link } from 'react-router-dom'



export default function OnlineShopping() {

    let currentIndex = 0;

    const handleScrollDown = () => {
        const allMainContainers = document.querySelectorAll('.main');

        if (allMainContainers.length > 0) {
            currentIndex = (currentIndex + 1) % allMainContainers.length;
            const nextContainer = allMainContainers[currentIndex];
            nextContainer.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <div className={styles.landing} id="main_content_div" >
            <div className={`${styles.main_container} main`}>
                <div className={styles.main_template}>
                    <div className={styles.main_section}>
                        <div className={styles.mainContentBody}>
                            <div className={styles.mainImages}>
                                <div className={styles.imageSideM}>
                                    <img className={styles.main_img} src={s1} unoptimized priority alt='' />
                                </div>
                            </div>
                            <div className={styles.mainContent}>
                                <div className={styles.mainHead}>
                                    <p className={styles.headingMain}><span>Your Ultimate Online Shopping Destination</span></p>
                                </div>
                                <p className={styles.headingMain}>
                                    Fashion, Beauty, Home Essentials, <br />
                                    Electronics, and More.
                                </p>
                                <p className={styles.bodyContentP} style={{ marginBottom: '0px' }}>
                                    Discover a vast range of quality products, from stylish fashion and beauty essentials to home décor and lifestyle accessories. Shop with confidence, knowing our collections are curated for quality and trend.
                                </p>
                                <p className={styles.bodyContentP} style={{ marginBottom: '0px' }}>
                                    Our electronics section features the latest gadgets, smart home devices, and accessories to meet all your tech needs, offering convenience and innovation right at your fingertips.
                                </p>
                                <p className={styles.bodyContentP} style={{ marginBottom: '0px' }}>
                                    We bring you premium brands and authentic products in every category, ensuring a smooth and satisfying online shopping experience.
                                </p>
                                <div className={styles.developButtonContainer}>
                                    <Link to='/shop' className={styles.developButtonBottom} >Explore More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${styles.main_container} main`}>
                <div className={styles.main_template}>
                    <div className={styles.main_section}>
                        <div className={styles.mainContentBody}>
                            <div className={styles.mainContent}>
                                <div className={styles.mainHead}>
                                    <p className={styles.headingMain}>Top Fashion and Beauty Brands</p>
                                </div>
                                <p className={styles.bodyContentP}>
                                    Stay in style with our exclusive range of clothing, accessories, and beauty products from renowned brands. Whether {`you're`} looking for chic outfits, skincare essentials, or luxury fragrances, our collection brings quality and elegance to every look.
                                </p>
                                <p className={styles.bodyContentP} style={{ marginBottom: '0px' }}>
                                    <strong>Luxury Watches:</strong> Discover our curated selection of luxury timepieces from prestigious brands, crafted with precision and elegance. From classic designs to modern innovations, our watches are more than accessories—they are timeless statements of sophistication.
                                </p>
                                <p className={styles.bodyContentP} style={{ marginBottom: '0px' }}>
                                    <strong>Designer Handbags and Accessories:</strong> Elevate any outfit with our range of designer handbags and premium accessories. Featuring iconic brands and styles, each piece is chosen for its quality craftsmanship and attention to detail, offering the perfect blend of functionality and luxury.
                                </p>
                                {/* <p className={styles.bodyContentP} style={{ marginBottom: '0px' }}>
                                    <strong>High-End Beauty and Skincare:</strong> Indulge in luxury skincare and beauty products from world-renowned brands. From rejuvenating serums to bold cosmetics, each item is designed to enhance your natural beauty and elevate your self-care routine.
                                </p> */}
                                <p className={styles.bodyContentP}>
                                    Our luxury collections bring you only the best in fashion and beauty, ensuring every item adds elegance, exclusivity, and charm to your personal style.
                                </p>
                                <div className={styles.developButtonContainer}>
                                    <Link to={'/shop'} className={styles.developButtonBottom} >Explore More</Link>
                                </div>
                            </div>
                            <div className={styles.mainImages}>
                                <div className={styles.imageSideM}>
                                    <img className={styles.main_img} src={s2} unoptimized priority alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className={`${styles.main_container} main`}>
                <div className={styles.main_template}>
                    <div className={styles.main_section}>
                        <div className={styles.mainContentBody}>
                            <div className={styles.mainImages}>
                                <div className={styles.imageSideM}>
                                    <img className={styles.main_img} src={s3} unoptimized priority alt='' />
                                </div>
                            </div>
                            <div className={styles.mainContent}>
                                <div className={styles.mainHead}>
                                    <p className={styles.headingMain}>Lifestyle Essentials and Electronics</p>
                                </div>
                                <p className={styles.bodyContentP}>
                                    From home décor and kitchen gadgets to the latest electronics, find everything you need to make life more convenient and enjoyable. Browse our tech collection for innovative devices, or explore home essentials that make everyday tasks easier and more enjoyable.
                                </p>
                                <p className={styles.bodyContentP} style={{ marginBottom: '0px' }}>
                                    <strong>Home Décor and Organization:</strong> Elevate your space with stylish décor and organizational tools. Our curated selection includes modern furnishings, wall art, and storage solutions to transform your home into a cozy and functional retreat.
                                </p>
                                {/* <p className={styles.bodyContentP} style={{ marginBottom: '0px' }}>
                                    <strong>Kitchen Essentials:</strong> Upgrade your culinary experience with top-notch kitchen gadgets and appliances. From precision blenders to compact air fryers, our collection is designed to simplify meal prep and inspire your inner chef.
                                </p> */}
                                <p className={styles.bodyContentP} style={{ marginBottom: '0px' }}>
                                    <strong>Smart Home Devices:</strong> Embrace the future of living with our range of smart home gadgets. Control lighting, monitor security, and automate daily tasks with the latest in smart technology, all designed to seamlessly integrate into your lifestyle.
                                </p>
                                <p className={styles.bodyContentP}>
                                    <strong>Entertainment and Audio:</strong> Unwind with high-quality entertainment options, including premium speakers, headphones, and streaming devices. Whether {`it's`} movie night or a quiet evening with music, we have everything to enhance your leisure time.
                                </p>
                                <Link to={'/shop'} className={styles.developButtonContainer}>
                                    <div className={styles.developButtonBottom}>Shop Now</div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}
