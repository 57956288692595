'use client'

import styles from '../process.module.css';
import side_image2 from '../../../assets/hnbrothers/howWeWork002.webp';
import side_image1 from '../../../assets/hnbrothers/howWeWork001.webp';
import side_image3 from '../../../assets/hnbrothers/howWeWork003.webp';
import { useRef } from 'react';
import useWindowSize from '../../our-services/useWindowSize';

export default function HowWeWork() {

    let currentIndex = 0;
    const slide_down_1 = () => {
        const allMainContainers = document.querySelectorAll('.main');

        if (allMainContainers.length > 0) {
            currentIndex = (currentIndex + 1) % allMainContainers.length;
            const nextContainer = allMainContainers[currentIndex];
            nextContainer.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <div className={styles.landing} id="main_content_div">
            <div className={`${styles.product_container} main`}>
                <div className={styles.product_item}>
                    <div className={styles.right_side} style={{ backgroundColor: '#f5f9fc' }}>
                        <img className={styles.image_full} src={side_image3} alt='' />
                    </div>
                    <div className={styles.left_side}>
                        <div className={styles.product_description}>
                            <div className={styles.mainMidContainer}>
                                <p className={styles.section_title}>Online Retail of Quality Goods</p>
                                <ul className={styles.list_items}>
                                    <li>Our online platform offers a range of high-quality products, including upscale accessories and beauty items, ensuring a premium shopping experience.</li>
                                    <li>We focus on sourcing and providing items that meet rigorous quality standards, delivering excellence in every product we offer.</li>
                                    <li>Our process involves understanding customer needs, ensuring product quality, and providing reliable delivery and support for an exceptional shopping experience.</li>
                                </ul>
                                <div className={styles.developButtonContainer} style={{ marginTop: '20px' }}>
                                    <div className={styles.developButtonBottom} onClick={slide_down_1}>Learn More</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${styles.product_container} main`}>
                <div className={styles.product_item}>
                    <div className={styles.left_side}>
                        <div className={styles.product_description}>
                            <div className={styles.mainMidContainer}>
                                <p className={styles.section_title}>Import of Perishable Produce</p>
                                <ul className={styles.list_items}>
                                    <li>We import premium fresh fruits, vegetables, cooking oils, and dry fruits from Brazil, Portugal, Morocco, and Uzbekistan, focusing on regions known for their natural beauty and high-quality produce.</li>
                                    <li>Our process includes understanding market demands, sourcing the finest produce, and ensuring rigorous quality checks to deliver fresh and high-quality items.</li>
                                    <li>We handle all logistics to ensure timely and efficient delivery, backed by our commitment to maintaining the highest standards of quality.</li>
                                </ul>
                                <div className={styles.developButtonContainer} style={{ marginTop: '20px' }}>
                                    <div className={styles.developButtonBottom} onClick={slide_down_1}>Learn More</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.right_side} style={{ backgroundColor: '#f5f9fc' }}>
                        <img className={styles.image_full} src={side_image2} alt='' />
                    </div>
                </div>
            </div>
            <div className={`${styles.product_container} main`}>
                <div className={styles.product_item}>
                    <div className={styles.right_side} style={{ backgroundColor: '#f5f9fc' }}>
                        <img className={styles.image_full} src={side_image1} alt='' />
                    </div>
                    <div className={styles.left_side}>
                        <div className={styles.product_description}>
                            <div className={styles.mainMidContainer}>
                                <p className={styles.section_title}>Export Solutions</p>
                                <div className={styles.platform_sub_title}>
                                    We export high-quality used car parts and motorcycles from the UK to diverse international markets, including Portugal, Morocco, Kyrgyzstan, and the Caribbean. Our parts, including engines and components for brands like Mercedes, BMW, and Toyota, are thoroughly checked to ensure they are in running condition.
                                </div>
                                <div className={styles.platform_sub_title}>
                                    Our process starts with understanding your specific needs, followed by meticulous sourcing and quality checks. We ensure timely and reliable delivery, supported by dedicated customer service to address any inquiries.
                                </div>
                                <div className={styles.developButtonContainer} style={{ marginTop: '20px' }}>
                                    <div className={styles.developButtonBottom} onClick={slide_down_1}>Learn More</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
}