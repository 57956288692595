import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { UserLogin, getUserData, removeUserData } from "../../../Utils/method"
import styles from '../../../assets/css/SignIn.module.css';
import classNames from "classnames";

function DriverSignIn() {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const login_modal = useSelector((state) => state.rootReducer.login_modal)
    const [passVisibility, setPassVisibility] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const LoginUser = async () => {
        try {
            let data = await UserLogin({ email: email, password: password, is_iosapp: false });
            getUserData({ data: data.user, type: 'set' });
            dispatch({ type: 'set_user_data', payload: data.user });
            toast.success(data.status_phrase, { autoClose: 1000 });
            if (login_modal.from === 'checkout') {
                dispatch({ type: 'set_login_modal', payload: { from: '', signin: false, signup: false } });
                navigate("/" + login_modal.from);
            } else {
                navigate("/");
            }
        } catch (error) {
            removeUserData();
            toast.error(error.status_phrase, { autoClose: 1000 });
        }
    }
    return (
        <div className={styles.sign_in_page_container}>
            <ToastContainer newestOnTop />
            <div className={styles.signin_partition}>
                <div className={styles.popup_nav}>
                    <Link to={"/"}><span> Home </span></Link> <span> {'>'} </span> <span> Driver </span> <span> {'>'} </span> <span> Sign In </span>
                    <span className={styles.closeCart} onClick={() => navigate(-1)}>
                        <i className="fa-thin fa-xmark"></i>
                    </span>
                </div>
                <div className={styles.form_content}>
                    <div className={styles.signin_form}>
                        <h1 className={styles.signin_title}>Driver Sign In</h1>
                        <form >
                            <div className={styles.container_login}>
                                <div className={styles.inputContSignin}>
                                    <input className={styles.inputs} type="text" placeholder="Email or Phone Number" name="uname" onChange={(event) => setEmail(event.target.value)} required value={email} />
                                </div>
                                <div className={styles.inputContSignin}>
                                    <input className={styles.inputs} type={!passVisibility ? "password" : "text"} minLength={6} placeholder="Password" name="psw" onChange={(event) => setPassword(event.target.value)} required value={password} />
                                    <span className={styles.viewPassword} onClick={() => setPassVisibility(!passVisibility)}><i className={classNames("fa-thin", passVisibility ? "fa-eye-slash" : "fa-eye")}></i></span> {/*<i className="fa-thin fa-eye-slash"></i> */}
                                </div>
                                <span className={styles.psw}><Link to="/forgot-password">Forgotten your password?</Link></span>
                                <label className={styles.rememberContainer}>Remember me
                                    <input type="checkbox" />
                                    <span className={styles.checkmark}></span>
                                </label>
                                {email && password ? <button className={styles.signin_button} type="button" onClick={LoginUser}>LOGIN</button> : <button className={styles.signin_button} type="button" disabled>LOGIN</button>}
                            </div>
                        </form>
                    </div>
                    <div className={styles.signin_form}>
                        <h1 className={styles.signin_title}>New to HN Brothers  . . .</h1>
                        <p className={styles.signintextMsg}>Creating an account is quick and simple and allows  you to track, change or return your order.</p>
                        <Link to={"/driver/sign-up"}> <button className={styles.signin_button} type="button" >CREATE AN ACCOUNT</button></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DriverSignIn;